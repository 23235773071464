import React, { useEffect} from 'react';
import { scrollToTop } from 'src/utils/helpers';
import { RegistrationCommodityCodeNew } from './AICommodityCode';

export const CommoditiesAndTagsRegistration = () => {

    useEffect(() => {
        scrollToTop();
    }, []);


    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='colWrapper'>
                            <div className='row'>
                                <div className='col col-8'>
                                    <h2 className='arrowWrapper mobileArrowWrapper'>2 of 4: Refine Bid Notifications</h2>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-sm-12 col-xl-7 col-md-8'>
                                    <h2>What are commodity codes?</h2>
                                    <p className='reg-intro no-top-padding'>
                                        DemandStar commodity codes are how we categorize goods and services for
                                        government procurement. When you choose commodity codes, DemandStar uses them to
                                        match what your company provides to what governments in your subscription need,
                                        helping you find the best bids and quotes to bid on.
                                    </p>
                                </div>
                            </div>

                            <RegistrationCommodityCodeNew />

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
