export const paths = {
  navigation: {
    addContractFromBid: (bidId: number | string) => `/buyers/contract/import/bid/${bidId}`,
    addContractInterstitial: (award: boolean) =>
      `/buyers/contract/add${award ? '?award=true' : ''}`,
    awardedBids: '/buyers/bids?bidStatus=AW&myBids=true',
    contractDetails: (contractId?: string) => `/buyers/contract/${contractId}`,
    createContract: '/buyers/contract/create',
    dashboard: '/buyers/contract',
    documents: (contractId?: string, tab?: string) => {
      return contractId
        ? `/buyers/contract/${contractId}/documents/${tab || ''}`
        : '/buyers/contract/documents/';
    },
    reminders: (
      contractId?: string,
      options?: {
        addReminder?: boolean;
        reminderId?: string;
      },
    ) => {
      const searchParams = new URLSearchParams();
      if (options?.addReminder) {
        searchParams.append('add', 'true');
      }

      if (options?.reminderId) {
        searchParams.append('reminder', options.reminderId);
      }

      const queryString = searchParams.toString();

      return (
        (contractId ? `/buyers/contract/${contractId}/reminders/` : '/buyers/contract/reminders/') +
        (queryString ? `?${queryString}` : '')
      );
    },
    editDraft: (contractId?: string) => `/buyers/contract/edit/${contractId}`,
    searchContracts: '/buyers/contract/search',
  },
  api: {
    awardees: {
      add: `/Contract/awardees/contacts`,
      delete: (contractId: string, awardeeContactId: string) =>
        `/Contract/${contractId}/awardees/contacts/${awardeeContactId}`,
      update: (contractId: string, awardeeContactId: string) =>
        `/Contract/${contractId}/awardees/contacts/${awardeeContactId}`,
    },
    contracts: {
       create: '/Contract',
        get: (contractId: string) => `/Contract/contractbyid/${contractId}`,
      search: '/Contract/search',
      update: (contractId: string) => `/Contract/update/${contractId}`,
      publish: (contractId: string) => `/Contract/activate/${contractId}`,
    },
    documents: {
      update:  `/Contract/documents`,
      delete: (contractId: string, documentId: string) =>
        `/Contract/${contractId}/documents/${documentId}`,
      types: {
        create: '/DocumentTypes/createorupdatedocumenttype',
        get: (documentTypeId: string) => `/DocumentTypes/documenttypebyid/${documentTypeId}`,
        list: '/DocumentTypes/documenttypes',
        update:  `/DocumentTypes/createorupdatedocumenttype`,
      },
    },
  },
} as const;
