/** TODO: fix naming convention, there's no reason this object needs to map 1:1 w/ environment variable names */
/* eslint-disable @typescript-eslint/naming-convention */
interface ConfigurationsTypes {
  AMPLITUDE_API_KEY: string;
  COOKIE_DOMAIN: string;
  GENERATE_SOURCEMAP?: boolean;
  REACT_APP_API_AGENCY_URL: string;
  REACT_APP_API_AUTHURL: string;
  REACT_APP_API_CONTRACT_DOCUMENT_URL: string;
  REACT_APP_API_CONTRACTURL: string;
  REACT_APP_API_DOCUMENTURL: string;
  REACT_APP_API_GRAPHQL_URL: string;
  REACT_APP_API_REMINDER_URL: string;
  REACT_APP_API_SUBSCRIPTIONURL: string;
  REACT_APP_API_TRACKURL: string;
  REACT_APP_API_URL_NO_AUTH?: string;
  REACT_APP_API_URL: string;
  REACT_APP_API_USERURL: string;
  REACT_APP_APP_GA: string;
  REACT_APP_APP_GTM: string;
  REACT_APP_APP_INSIGHTS_KEY: string;
  REACT_APP_BASE_URL: string;
  REACT_APP_BTKey: string;
  REACT_APP_CONTENT_BLOB_URL: string;
  REACT_APP_CORP_URL: string;
  REACT_APP_DS_ENV: string;
  REACT_APP_GOOGLE_RECAPTCHA: string;
  REACT_APP_IMAGES_CDN: string;
  REACT_APP_NETWORK_URL: string;
  REACT_APP_OPS_URL: string;
  REACT_APP_PASSWORD_RESET_URL: string;
  REACT_APP_REDIRECT_HOME_URL: string;
  REACT_APP_ROUTER_BASE_URL: string;
  REACT_APP_VERSION?: string;
  COGNITIVE_SEARCH_BID_TAGS_ENDPOINT : string;
  COGNITIVE_SEARCH_BID_TAGS_INDEX : string;
  COGNITIVE_SEARCH_BID_TAGS_API_KEY : string;
  COGNITIVE_SEARCH_BID_TAGS_API_VERSION : string;
  COGNITIVE_SEARCH_COMMODITY_CODES_ENDPOINT : string;
  COGNITIVE_SEARCH_COMMODITY_CODES_SEMANTIC: string;
  COGNITIVE_SEARCH_COMMODITY_CODES_INDEX : string;
  COGNITIVE_SEARCH_COMMODITY_CODES_API_KEY : string;
  COGNITIVE_SEARCH_COMMODITY_CODES_VERSION : string;
}

// This isn't an enum because when you use the value via
// process.env.REACT_APP_DS_ENV the type is not used outside of this file
// throwing an enum !== string | undefined type error.
export const DSEnvTypes = {
  development: 'DEVELOPMENT',
  qa: 'QA',
  staging: 'STAGING',
  production: 'PRODUCTION',
} as const;

let Configurations: ConfigurationsTypes;

/** Only add new API paths here
 * New paths should follow the pattern {baseApiUrl}/{serviceName}/{version?} */
function getApiPaths(baseApiUrl: string) {
  return {
    REACT_APP_API_AGENCY_URL: `${baseApiUrl}/contents`,
    REACT_APP_API_AUTHURL: `${baseApiUrl}/auth/access/v1`,
    REACT_APP_API_CONTRACT_DOCUMENT_URL: `${baseApiUrl}/docs/v1`,
    REACT_APP_API_CONTRACTURL: `${baseApiUrl}/contracts/v1`,
    REACT_APP_API_DOCUMENTURL: `${baseApiUrl}/documents/content/v1`,
    REACT_APP_API_GRAPHQL_URL: 'https://dsstg-gateway.azurewebsites.net/graphql',
    REACT_APP_API_REMINDER_URL: `${baseApiUrl}/reminders/v1`,
    REACT_APP_API_SUBSCRIPTIONURL: `${baseApiUrl}/payments/account/v1`,
    REACT_APP_API_TRACKURL: `${baseApiUrl}/tracking/content/v1`,
    REACT_APP_API_URL: `${baseApiUrl}/contents/content/v1`,
    REACT_APP_API_USERURL: `${baseApiUrl}/users/accounts/v1`,
  };
}

/** TODO: Migrate legacy /{serviceName}-qa routes to /{serviceName} routes
 * and update test stubs */
/** @deprecated
 * New paths should follow the pattern {baseApiUrl}/{serviceName}/{version?} */
function getQaApiPaths() {
  return {
    REACT_APP_API_AGENCY_URL: 'https://apiqa.demandstar.com/contents-qa/',
    REACT_APP_API_AUTHURL: `https://dsqawinappauth.azurewebsites.net/access/v1`,
    REACT_APP_API_CONTRACT_DOCUMENT_URL: 'https://apiqa.demandstar.com/docs-qa/v1',
    REACT_APP_API_CONTRACTURL: 'https://apiqa.demandstar.com/contracts-qa/v1',
    REACT_APP_API_DOCUMENTURL: 'https://apiqa.demandstar.com/documents-qa/content/v1',
    REACT_APP_API_GRAPHQL_URL: 'https://dsstg-gateway.azurewebsites.net/graphql',
    REACT_APP_API_REMINDER_URL: 'https://dsqawinappcontract.azurewebsites.net/api',
    REACT_APP_API_SUBSCRIPTIONURL: 'https://apiqa.demandstar.com/subscription-qa/account/v1',
    REACT_APP_API_TRACKURL: 'https://apiqa.demandstar.com/tracking-qa/content/v1',
    REACT_APP_API_URL: 'https://apiqa.demandstar.com/contents-qa/content/v1',
    REACT_APP_API_USERURL: 'https://apiqa.demandstar.com/users-qa/accounts/v1',
  };
}

function getQAPenTestApiPaths() {
  return {
    REACT_APP_API_AGENCY_URL: 'https://apiqa.demandstar.com/contents-qapt/',
    REACT_APP_API_AUTHURL: 'https://apiqa.demandstar.com/auth-qapt/access/v1',
    REACT_APP_API_CONTRACT_DOCUMENT_URL: 'https://apiqa.demandstar.com/docs-qapt/v1',
    REACT_APP_API_CONTRACTURL: 'https://apiqa.demandstar.com/contracts-qapt/v1',
    REACT_APP_API_DOCUMENTURL: 'https://apiqa.demandstar.com/documents-qapt/content/v1',
    REACT_APP_API_GRAPHQL_URL: 'https://dsstg-gateway.azurewebsites.net/graphql',
    REACT_APP_API_REMINDER_URL: 'https://dsqawinappcontract.azurewebsites.net/api',
    REACT_APP_API_SUBSCRIPTIONURL: 'https://apiqa.demandstar.com/subscription-qapt/account/v1',
    REACT_APP_API_TRACKURL: 'https://apiqa.demandstar.com/tracking-qapt/content/v1',
    REACT_APP_API_URL: 'https://apiqa.demandstar.com/contents-qapt/content/v1',
    REACT_APP_API_USERURL: 'https://apiqa.demandstar.com/users-qapt/accounts/v1',
  };
}

/** Leaving production hard-coded to avoid potential errors */
if (window.location.hostname === 'www.demandstar.com') {
  Configurations = {
    ...getApiPaths('https://api.demandstar.com'),
    AMPLITUDE_API_KEY: '53320efd760ea246efbbd5d2b922c998',
    COOKIE_DOMAIN: '.demandstar.com',
    GENERATE_SOURCEMAP: false,
    REACT_APP_APP_GA: 'G-QLPM2XWL45',
    REACT_APP_APP_GTM: 'GTM-N8N2WL4',
    REACT_APP_APP_INSIGHTS_KEY: 'c9493017-35ce-42e0-b806-d0144da2e8a6',
    REACT_APP_BASE_URL: 'https://www.demandstar.com/app',
    REACT_APP_BTKey: 'production_k2kkd7x2_d3fybhcsdg78cdpv',
    REACT_APP_CONTENT_BLOB_URL: 'https://networkstaticcontent.blob.core.windows.net/agencycontent/',
    REACT_APP_CORP_URL: 'https://network.demandstar.com/',
    REACT_APP_DS_ENV: DSEnvTypes.production,
    REACT_APP_GOOGLE_RECAPTCHA: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    REACT_APP_IMAGES_CDN: 'https://webappcontent.blob.core.windows.net/appfiles/images',
    REACT_APP_NETWORK_URL: 'https://network.demandstar.com',
    REACT_APP_OPS_URL: 'https://ops.demandstar.com',
    REACT_APP_PASSWORD_RESET_URL: 'https://www.demandstar.com/app/passwordreset',
    REACT_APP_REDIRECT_HOME_URL: '/app/login',
    REACT_APP_ROUTER_BASE_URL: '/app',
    REACT_APP_VERSION: '004',
    COGNITIVE_SEARCH_BID_TAGS_ENDPOINT : 'https://dsbidtagsearch.search.windows.net',
    COGNITIVE_SEARCH_BID_TAGS_INDEX : 'dsprodbidtagssearch',
    COGNITIVE_SEARCH_BID_TAGS_API_KEY : '7gxFOpM2xM0Zh6dHsKsUmnEsEVWX5trpSYl4ZP3IFAAzSeCuA5Tw',
    COGNITIVE_SEARCH_BID_TAGS_API_VERSION :'2024-05-01-preview&search',
    COGNITIVE_SEARCH_COMMODITY_CODES_ENDPOINT : 'https://dsbidtagsearch.search.windows.net',
    COGNITIVE_SEARCH_COMMODITY_CODES_INDEX : 'dsprodcommodityindex',
    COGNITIVE_SEARCH_COMMODITY_CODES_API_KEY : '7gxFOpM2xM0Zh6dHsKsUmnEsEVWX5trpSYl4ZP3IFAAzSeCuA5Tw',
    COGNITIVE_SEARCH_COMMODITY_CODES_VERSION : '2024-05-01-preview&search',
    COGNITIVE_SEARCH_COMMODITY_CODES_SEMANTIC:'dsprodcommodityconfig',
  };
} else if (window.location.hostname === 'qapt.demandstar.com') {
  const apiPaths = getQAPenTestApiPaths();
  Configurations = {
    ...apiPaths,
    AMPLITUDE_API_KEY: '3bc541b379836d9f52b35e05ced1d186',
    COOKIE_DOMAIN: '.demandstar.com',
    GENERATE_SOURCEMAP: false,
    REACT_APP_APP_GA: 'G-W41YRBXQBH',
    REACT_APP_APP_GTM: 'GTM-5GF3XR5',
    REACT_APP_APP_INSIGHTS_KEY: 'c1045fb5-0535-4666-8490-b7d6423bdd04',
    REACT_APP_BASE_URL: `https://${window.location.hostname}/app`,
    REACT_APP_BTKey: 'sandbox_j7rrb2dm_48m7xkkkzs46xghs',
    REACT_APP_CONTENT_BLOB_URL: 'https://networksitecontent.blob.core.windows.net/agecnycontent/',
    REACT_APP_CORP_URL: 'https://network.demandstar.com/',
    REACT_APP_DS_ENV: DSEnvTypes.qa,
    REACT_APP_GOOGLE_RECAPTCHA: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    REACT_APP_IMAGES_CDN: 'https://webappcontent.blob.core.windows.net/appfiles/images',
    REACT_APP_NETWORK_URL: 'https://www.network.demandstar.com',
    REACT_APP_OPS_URL: 'https://qaptops.demandstar.com',
    REACT_APP_PASSWORD_RESET_URL: `https://${window.location.hostname}/securitycodesignin`,
    REACT_APP_REDIRECT_HOME_URL: '/app/login',
    REACT_APP_ROUTER_BASE_URL: '/app',
    REACT_APP_VERSION: '026',
    COGNITIVE_SEARCH_BID_TAGS_ENDPOINT : 'https://dsqabidsearch.search.windows.net',
    COGNITIVE_SEARCH_BID_TAGS_INDEX : 'dsqabidtagsindex',
    COGNITIVE_SEARCH_BID_TAGS_API_KEY : 'KQodRdkHhVWUgyuer3BJWQsVtCm1FSUKQyTHrUP3AuAzSeDCgWjG',
    COGNITIVE_SEARCH_BID_TAGS_API_VERSION :'2024-05-01-preview&search',
    COGNITIVE_SEARCH_COMMODITY_CODES_ENDPOINT : 'https://dsqabidsearch.search.windows.net',
    COGNITIVE_SEARCH_COMMODITY_CODES_INDEX : 'dsqacommodityindex',
    COGNITIVE_SEARCH_COMMODITY_CODES_API_KEY : 'KQodRdkHhVWUgyuer3BJWQsVtCm1FSUKQyTHrUP3AuAzSeDCgWjG',
    COGNITIVE_SEARCH_COMMODITY_CODES_VERSION : '2024-05-01-preview&search',
    COGNITIVE_SEARCH_COMMODITY_CODES_SEMANTIC:'dsqacmtySemSearch',
  };
} else if (window.location.hostname !== 'localhost') {
  /** If not production host domain, try to grab settings from the environment.
   * Leaving QA defaults when env isn't specified to avoid unexpected errors */
  const apiPaths = process.env.REACT_APP_API_BASE_URL
    ? getApiPaths(process.env.REACT_APP_API_BASE_URL)
    : getQaApiPaths();
  Configurations = {
    ...apiPaths,
    AMPLITUDE_API_KEY: '3bc541b379836d9f52b35e05ced1d186',
    COOKIE_DOMAIN: window.location.hostname === 'localhost:3000' ? 'localhost' : '.demandstar.com',
    GENERATE_SOURCEMAP: false,
    REACT_APP_APP_GA: 'G-W41YRBXQBH',
    REACT_APP_APP_GTM: 'GTM-5GF3XR5',
    REACT_APP_APP_INSIGHTS_KEY: 'c1045fb5-0535-4666-8490-b7d6423bdd04',
    REACT_APP_BASE_URL: `https://${window.location.hostname}/app`,
    REACT_APP_BTKey: 'sandbox_j7rrb2dm_48m7xkkkzs46xghs',
    REACT_APP_CONTENT_BLOB_URL: 'https://networksitecontent.blob.core.windows.net/agecnycontent/',
    REACT_APP_CORP_URL: 'https://network.demandstar.com/',
    REACT_APP_DS_ENV:
      window.location.hostname === 'staging.demandstar.com' ? DSEnvTypes.staging : DSEnvTypes.qa,
    REACT_APP_GOOGLE_RECAPTCHA: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    REACT_APP_IMAGES_CDN: 'https://webappcontent.blob.core.windows.net/appfiles/images',
    REACT_APP_NETWORK_URL: 'https://www.network.demandstar.com',
    REACT_APP_OPS_URL: 'https://qaops.demandstar.com',
    REACT_APP_PASSWORD_RESET_URL: `https://${window.location.hostname}/securitycodesignin`,
    REACT_APP_REDIRECT_HOME_URL: '/app/login',
    REACT_APP_ROUTER_BASE_URL: '/app',
    REACT_APP_VERSION: '026',

    COGNITIVE_SEARCH_BID_TAGS_ENDPOINT : 'https://dsqabidsearch.search.windows.net',
    COGNITIVE_SEARCH_BID_TAGS_INDEX : 'dsqabidtagsindex',
    COGNITIVE_SEARCH_BID_TAGS_API_KEY : 'KQodRdkHhVWUgyuer3BJWQsVtCm1FSUKQyTHrUP3AuAzSeDCgWjG',
    COGNITIVE_SEARCH_BID_TAGS_API_VERSION :'2024-05-01-preview&search',
    COGNITIVE_SEARCH_COMMODITY_CODES_ENDPOINT : 'https://dsqabidsearch.search.windows.net',
    COGNITIVE_SEARCH_COMMODITY_CODES_INDEX : 'dsqacommodityindex',
    COGNITIVE_SEARCH_COMMODITY_CODES_API_KEY : 'KQodRdkHhVWUgyuer3BJWQsVtCm1FSUKQyTHrUP3AuAzSeDCgWjG',
    COGNITIVE_SEARCH_COMMODITY_CODES_VERSION : '2024-05-01-preview&search',
    COGNITIVE_SEARCH_COMMODITY_CODES_SEMANTIC:'dsqacmtySemSearch',
  };
} else {
  const apiPaths = process.env.REACT_APP_API_BASE_URL
    ? getApiPaths(process.env.REACT_APP_API_BASE_URL)
    : getQaApiPaths();
  /** Custom handling for localhost (DEV ENV) */
  Configurations = {
    ...apiPaths,
    AMPLITUDE_API_KEY: '3bc541b379836d9f52b35e05ced1d186',
    COOKIE_DOMAIN: 'localhost',
    REACT_APP_API_GRAPHQL_URL: 'https://dsstg-gateway.azurewebsites.net/graphql',
    REACT_APP_APP_GA: 'G-W41YRBXQBH',
    REACT_APP_APP_GTM: 'GTM-5GF3XR5',
    REACT_APP_APP_INSIGHTS_KEY: 'c1045fb5-0535-4666-8490-b7d6423bdd04',
    REACT_APP_BASE_URL: 'http://localhost:3000/app',
    REACT_APP_BTKey: 'sandbox_j7rrb2dm_48m7xkkkzs46xghs',
    REACT_APP_CONTENT_BLOB_URL: 'https://networksitecontent.blob.core.windows.net/agecnycontent/',
    REACT_APP_CORP_URL: 'https://network.demandstar.com/',
    REACT_APP_DS_ENV: DSEnvTypes.development,
    REACT_APP_GOOGLE_RECAPTCHA: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    REACT_APP_IMAGES_CDN: 'https://webappcontent.blob.core.windows.net/appfiles/images',
    REACT_APP_NETWORK_URL: 'https://staging12.network.demandstar.com',
    REACT_APP_OPS_URL: 'https://localhost:44317',
    REACT_APP_PASSWORD_RESET_URL: 'http://localhost:3000/app/passwordreset',
    REACT_APP_REDIRECT_HOME_URL: '/app/login',
    REACT_APP_ROUTER_BASE_URL: '/app',
    COGNITIVE_SEARCH_BID_TAGS_ENDPOINT : 'https://dsqabidsearch.search.windows.net',
    COGNITIVE_SEARCH_BID_TAGS_INDEX : 'dsqabidtagsindex',
    COGNITIVE_SEARCH_BID_TAGS_API_KEY : 'KQodRdkHhVWUgyuer3BJWQsVtCm1FSUKQyTHrUP3AuAzSeDCgWjG',
    COGNITIVE_SEARCH_BID_TAGS_API_VERSION :'2024-05-01-preview&search',
    COGNITIVE_SEARCH_COMMODITY_CODES_ENDPOINT : 'https://dsqabidsearch.search.windows.net',
    COGNITIVE_SEARCH_COMMODITY_CODES_INDEX : 'dsqacommodityindex',
    COGNITIVE_SEARCH_COMMODITY_CODES_API_KEY : 'KQodRdkHhVWUgyuer3BJWQsVtCm1FSUKQyTHrUP3AuAzSeDCgWjG',
    COGNITIVE_SEARCH_COMMODITY_CODES_VERSION : '2024-05-01-preview&search',  
    COGNITIVE_SEARCH_COMMODITY_CODES_SEMANTIC:'dsqacmtySemSearch',
  };
}

export default Configurations;
