export function DevCheck(){


    function OpenWindowWithPost(url, name, params)
    {
        const form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", url);
        form.setAttribute("target", name);

        for (const i in params) {
            if (params.hasOwnProperty(i)) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = i;
                input.value = params[i];
                form.appendChild(input);
            }
        }

        document.body.appendChild(form);
        window.open("", name);
        
        form.submit();
        
        document.body.removeChild(form);
    }

    async function generateToken(){
        const authUrl = "https://dsqawinappauth.azurewebsites.net/access/v1/bid/generatetoken";
        const supplierParams = {
            "EunaVendorId":"ESN031",
            "CompanyName":"ESN Vendor 031",
            "FirstName":"ESN031",
            "lastName":"User",
            "Email":"esnVendor031@example.com",
            "subscriptionProducts": [
                {
                    "name":"Florida",
                    "type":"State",
                }, 
                {
                    "name":"Bay County",
                    "type":"County",
                },
            ],
            "subscriptionStartDate":"2024-04-10",
            "subscriptionEndDate":"2025-04-10",
            "address1":"12, new st",
            "address2":"",
            "city":"Fort Lauderdale",
            "county":"Broward County",
            "state":"Florida",
            "postalCode":"33319",
            "phoneNumber":"(34-7) -791-4136",
        };

        const response = await fetch(authUrl, {
            method:"POST",
            body:JSON.stringify(supplierParams),
            headers:{
                'Content-Type':"application/json",
                'Accept':'application/json',
            },
        });
        const responseData = await response.json();
        return responseData.token;
    }

    async function authAndRedirect(){
        const token = await generateToken();
        const bidurl = "https://dsqawinappauth.azurewebsites.net/access/v1/bid/details";
            
        const param = { 
                        "tk":token, 
                        bidId: 427718,
                    };		

        OpenWindowWithPost(bidurl, "Bid Detail", param);
    }

    return (
        <>
            <div className='container loginWrapper'>
                <div
                className='row d-flex justify-content-center align-items-center'
                style={{ height: '100vh' }}
                >
                    <div className='col-10 col-md-5'>
                        <div onClick={authAndRedirect}>Bid detail Link</div>
                    </div>
                </div>
            </div>
        </>
    );
};
