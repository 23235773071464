import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useResetRecoilState } from 'recoil';

import {
  AwardBidEventName,
  useAwardBidEvents,
} from '../../../shared/hooks/amplitude/awardBid.events';
import { AwardBidLabels, defaultNumericId } from '../../../shared/constants';
import { AwardWizardId, selectedBidAwardeeState } from '../../../store/recoil/award-bid';
import { BidExternalStatusType, Pages } from '../../../utils/constants';
import { DocStatus, DocTypeValue } from '../../../types/addbid';
import {
  notifyAwardeesState,
  notifyColleaguesState,
  notifyUnselectedState,
} from '../../../store/recoil/award-bid';
import { useAwardBidWizard, useRouter, useSetBreadcrumb } from '../../../shared/hooks';
import {
  useSelectedBidId,
  useSetSelectedBidIdFromRoute,
} from '../../../shared/hooks/useSelectedBidId';
import { AddPublicDocs } from './AddPublicDocs';
import { AddVendorDocs } from './add-vendor-docs';
import { AwardBidControl } from './AwardBidControl';
import { FinalizeAward } from './finalize-award/FinalizeAward';
import { ListRequiredDocuments } from './list-required-docs/ListRequiredDocuments';
import { markBidAsAwarded } from '../../../store/services/awardBid';
import { NotifyAwardees } from './notify-pages/notify-awardees/NotifyAwardees';
import { NotifyColleagues } from './notify-pages/notify-colleagues/NotifyColleagues';
import { NotifyUnselectedBidders } from './notify-pages/notify-unselected/NotifyUnselectedBidders';
import { SelectAwardees } from './select-awardees/SelectAwardees';
import { selectedBidIdState } from '../../../store/recoil/bidState';
import { setSharedDetails, submitAddBidDocumentApprove } from '../../../store/actions';
import { useBid } from 'src/shared/hooks/useBid';
import { useDSSelector } from '../../../store/reducers';
import { useMemberAwards } from 'src/features/contract-management/buyer/dashboard/useMemberAwards';
import { useResetOnUnmount } from '../../../shared/hooks/useResetOnUnmount';
import { WizardContainer } from '../../customcontrols/wizard/WizardContainer';
import { WizardPage } from '../../../types/wizard';
import { useAuthContext } from 'src/auth/AuthProvider';

const awardWizardComponents = {
  'Select Awardees': SelectAwardees,
  'Send Documents To Specific Awardees': AddVendorDocs,
  'Get Documents From Awardees': ListRequiredDocuments,
  'Add Public Documents': AddPublicDocs,
  'Notify Awardees': NotifyAwardees,
  'Notify Unselected Bidders': NotifyUnselectedBidders,
  'Notify Colleagues': NotifyColleagues,
  'Finalize Award': FinalizeAward,
};

type AwardPage = keyof typeof awardWizardComponents;

type AwardWizardPage = WizardPage<AwardWizardId, AwardPage>;

export type AwardWizardComponentProps = {
  completeWizard: () => void;
  selectedBidId: number;
};

export function AwardBid(): JSX.Element {
  // Hooks
  const {
    addPublicDocsStatus,
    addVendorDocsStatus,
    awardWizardId,
    finalizeAwardStatus,
    invalidAwardWizard,
    listRequiredDocsStatus,
    notifyAwardeeStatus,
    notifyInternalStatus,
    notifyUnselectedStatus,
    refreshAwardBidWizard,
    saveAwardBidWizard,
    selectAwardeeStatus,
    setAwardWizardId,
    setSubmitting,
    submitting,
  } = useAwardBidWizard();

  const { bid, refreshBid } = useBid();

  // Updates graph data for contract management dashboard
  const { refreshMemberAwards } = useMemberAwards();

  // Update the selected bidId from the current url params.
  useSetSelectedBidIdFromRoute();

  // Reset Recoil state on dismount
  // TODO: test and combine reset useEffect calls
  useResetOnUnmount(selectedBidIdState);
  useResetOnUnmount(selectedBidAwardeeState);

  // - Route-derived state
  const { selectedBidId } = useSelectedBidId();

  const dispatch = useDispatch();
  const { logEvent } = useAwardBidEvents();

  const { history } = useRouter();

  const { auth } = useAuthContext();
  // - Bid Summary

  const documentList = useDSSelector(state => state.addbid.documentslist);

  const bidExternalStatusType = bid?.bidExternalStatusType;
  const bidName = bid?.bidName || '';
  const bidMemberId = bid?.memberId || 0;

  const unapprovedAwardDocs = documentList.filter(
    doc =>
      doc.docType === DocTypeValue.AwardDocument && doc.statusType === DocStatus.ApprovalPending,
  );

  // resets
  const resetAwardeesEmail = useResetRecoilState(notifyAwardeesState);
  const resetUnselectedEmail = useResetRecoilState(notifyUnselectedState);
  const resetColleaguesEmail = useResetRecoilState(notifyColleaguesState);

  const awardWizardPages: AwardWizardPage[] = React.useMemo(
    () => [
      {
        id: AwardWizardId.SelectAwardees,
        name: 'Select Awardees',
        status: selectAwardeeStatus,
      },
      {
        id: AwardWizardId.AddVendorDocs,
        name: 'Send Documents To Specific Awardees',
        status: addVendorDocsStatus,
      },
      {
        id: AwardWizardId.ListRequiredDocs,
        name: 'Get Documents From Awardees',
        status: listRequiredDocsStatus,
      },
      {
        id: AwardWizardId.AddPublicDocs,
        name: 'Add Public Documents',
        status: addPublicDocsStatus,
      },
      {
        id: AwardWizardId.NotifyAwardees,
        name: 'Notify Awardees',
        status: notifyAwardeeStatus,
      },
      {
        id: AwardWizardId.NotifyUnselected,
        name: 'Notify Unselected Bidders',
        status: notifyUnselectedStatus,
      },
      {
        id: AwardWizardId.NotifyColleagues,
        name: 'Notify Colleagues',
        status: notifyInternalStatus,
      },
      {
        id: AwardWizardId.FinalizeAward,
        name: 'Finalize Award',
        status: finalizeAwardStatus,
      },
    ],
    [
      addPublicDocsStatus,
      addVendorDocsStatus,
      finalizeAwardStatus,
      listRequiredDocsStatus,
      notifyAwardeeStatus,
      notifyInternalStatus,
      notifyUnselectedStatus,
      selectAwardeeStatus,
    ],
  );

  const currentWizardPage = useMemo(() => {
    return (
      awardWizardPages.find(awardPage => {
        return awardPage.id === awardWizardId;
      }) || awardWizardPages[0]
    );
  }, [awardWizardPages, awardWizardId]);

  const approveAwardDocs = useCallback(() => {
    // TODO: batch approved docs and update service to avoid multiple calls
    for (const doc of unapprovedAwardDocs) {
      dispatch(
        submitAddBidDocumentApprove({
          bidId: selectedBidId,
          bidDocId: doc.bidDocId,
        }),
      );
    }
  }, [selectedBidId, dispatch, unapprovedAwardDocs]);

  const completeWizard = useCallback(async () => {
    dispatch(setSharedDetails({ showLoader: true }));
    setSubmitting(true);
    approveAwardDocs();
    logEvent({ eventName: AwardBidEventName.AwardBid });
    await markBidAsAwarded(selectedBidId);
    refreshBid();
    refreshMemberAwards();
    dispatch(setSharedDetails({ showLoader: false }));
  }, [approveAwardDocs, logEvent, refreshBid, refreshMemberAwards, selectedBidId, setSubmitting,dispatch]);

  useEffect(() => {
    // This ensures we have the latest data for our wizard.
    if (auth?.memberId !== defaultNumericId) {
      refreshAwardBidWizard(selectedBidId);
    }
  }, [auth, selectedBidId, refreshAwardBidWizard]);

  useEffect(() => {
    // Re-direct if memberId does not match.
    if (Number(auth?.memberId) > 0 && bidMemberId > 0 && auth?.memberId !== bidMemberId) {
      history.push(`/buyers/bids/${selectedBidId}`);
    }
  }, [auth, selectedBidId, bidMemberId, history]);

  useSetBreadcrumb({
    altname: currentWizardPage.name,
    component: <AwardBidControl bidId={selectedBidId} />,
    id: selectedBidId.toString(),
    menuactive: 'bids',
    name: bidName,
    page: 'Agency Bid Award',
    title: 'title',
  });

  // Cleanup
  useEffect(() => {
    return () => {
      // onDestroy, reset:
      // - bidSummary
      // - eBids
      // - selectedBidId
      setAwardWizardId(AwardWizardId.SelectAwardees);
      resetAwardeesEmail();
      resetUnselectedEmail();
      resetColleaguesEmail();
      setSubmitting(false);
    };
  }, [
    dispatch,
    resetAwardeesEmail,
    resetColleaguesEmail,
    resetUnselectedEmail,
    setAwardWizardId,
    setSubmitting,
  ]);

  useEffect(() => {
    // when Recoil gives us confirmation that the bid has been awarded, ONLY then do we navigate
    if (submitting && bidExternalStatusType === BidExternalStatusType.Awarded) {
      if (history) {
        if (selectedBidId) {
          history.push(`${Pages.buyerBids}/${selectedBidId}`);
        } else {
          history.goBack();
        }
      }
    }
  }, [bidExternalStatusType, selectedBidId, history, submitting]);

  useEffect(() => {
    /** Better safe than sorry.
     * Until we have the whole site making use of useBid, it's best to get the latest data.*/
    refreshBid();
  }, [refreshBid]);

  const WizardComponent = awardWizardComponents[currentWizardPage.name];

  const awardWizardComponentProps = {
    completeWizard,
    selectedBidId,
  };

  return (
    <WizardContainer
      navTitle={AwardBidLabels.progressTitle}
      wizard={awardWizardPages}
      currentWizard={currentWizardPage}
      header={`Award ${bidName}`}
      navigateTo={saveAwardBidWizard}
      disableNav={invalidAwardWizard}
    >
      {bidMemberId > 0 && Number(auth?.memberId) > 0 ? (
        <WizardComponent {...awardWizardComponentProps} />
      ) : (
        <></>
      )}
    </WizardContainer>
  );
}
