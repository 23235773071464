import * as paths from './reminders.paths';
import Api,{ axiosBarePostAuthenticated, axiosBareRequestAuthenticated } from 'src/store/services/api';
import { ContractReminder, ContractReminderAPI, ReminderPreviewPreferences } from './reminders';
import { constants } from 'src/utils/settings';

export async function createReminder(reminder: ContractReminder) {
  const createReminderResponse = await axiosBarePostAuthenticated({
    baseURL: constants.api.reminderUrl,
    data: reminder,
    url: paths.create,
  }) as {data:ContractReminderAPI};
  return createReminderResponse.data ;
}

export async function getReminder(id: string) {
  const getReminserResponse = await axiosBareRequestAuthenticated({
    baseURL: constants.api.reminderUrl,
    url: paths.get(id),
  }) as {data:ContractReminderAPI};
  return getReminserResponse.data;
}

export async function sendReminder(id: string) {
  const sendReminderResponse = await axiosBarePostAuthenticated({
    baseURL: constants.api.reminderUrl,
    url: paths.send(id),
  }) as {data:ContractReminderAPI};
  return sendReminderResponse.data;
}

export async function searchReminders(params: { contractId?: string }) {
  const searchReminderResponse = await axiosBarePostAuthenticated({
    baseURL: constants.api.reminderUrl,
    data: params,
    url: paths.search,
  }) as {data:ContractReminderAPI[]};
  return searchReminderResponse.data ;
}

export async function updateReminder(reminder: ContractReminder) {
  const updateReminderResponse = await  Api.getRequestWithAuthentication(paths.update(reminder.id), {
    baseURL: constants.api.reminderUrl,
  }) as {data:any};
  return updateReminderResponse.data.data as ContractReminderAPI ;
  
}

export async function getPreviewPreferences() {
  return axiosBareRequestAuthenticated<ReminderPreviewPreferences>({
    baseURL: constants.api.reminderUrl,
    url: paths.getPreviewPreferences,
  });
}

export async function setPreviewPreferences(hidePreview = false) {
  return axiosBarePostAuthenticated<ContractReminderAPI>({
    baseURL: constants.api.reminderUrl,
    data: {
      hidePreview,
    },
    url: paths.setPreviewPreferences,
  });
}
