import { useEffect, useMemo } from "react";
import Suspenseloader from "../splash/suspenseloader";
import { useHistory, useParams } from "react-router-dom";
import { useAuthContext } from "../../../auth/AuthProvider";
import { useDispatch } from "react-redux";
import { useRouter } from "../../../shared/hooks";
import { BROADCAST_CHANNEL_AUTH_ID, broadcastChannelReload } from "../../../utils/helpers/broadcast-channel";

export function ESNLogin(){

    const params = useParams() as any;
    const dispatch = useDispatch();
    const { handleESNLogin } = useAuthContext();
    const { push } = useHistory();
    const bcAuth = useMemo(() => new BroadcastChannel(BROADCAST_CHANNEL_AUTH_ID), []);
    const { history } = useRouter();

    useEffect(() => {
        const token = params.token;
        const bidId = params.bidId;
        broadcastChannelReload(bcAuth);
        handleESNLogin(token);
        const redirectURL = `/suppliers/bids/${bidId}/details`;
        if (redirectURL) {
            history.push(redirectURL);
        }
    }, [params, bcAuth, handleESNLogin, history]);

    return <Suspenseloader />;
}
