import { limitedPaths } from 'src/shared/constants';
import { Route } from '../../types/routes';

// Routes of View Components
import AccountRecovery from '../../components/limited/accountrecovery';
import AgencyLanding from '../../components/limited/agencylanding';
import AnonymousAgency from '../../components/anonymous/index';
import ASPAgency from '../../components/limited/aspagency';
import BidLimitExceeded from '../../components/common/splash';
import DownloadErrorModal from '../../components/common/documenterror';
import EmailRecovery from '../../components/limited/emailrecovery';
import LimitedBidsDetails from '../../components/limited/bids/details';
import { Login } from '../../components/common/login/login';
import MailValidator from '../../components/common/emailvalidator';
import ResetPassword from '../../components/limited/resetpassword';
import WappRegistration from '../../components/limited/wappregistration';

import { BrowseBids } from 'src/features/bids/browse/BrowseBids';
import { LoginFromCookie } from '../../components/common/login/loginfromcookie';
import { PageNotFound } from '../../components/common/pagenotfound';
import { Registration } from '../../components/products/registration';
import { ESNLogin } from '../../components/common/login/ESNLogin';
import { DevCheck } from '../../components/common/login/devcheck';

export const limitedRoutes: Route[] = [
  {
    path: '/login',
    component: Login,
    page: 'Login',
    breadcrumb: { Home: '' },
    shouldIndex: true,
  },
  {
    path: '/loginbytoken',
    component: LoginFromCookie,
    page: 'Login from Cookie',
    breadcrumb: { Home: '' },
  },
  {
    path: '/bidlimitexceeded',
    component: BidLimitExceeded,
    page: 'Bid Limit Exceed',
    breadcrumb: { Home: '' },
  },
  {
    path: '/pagenotfound',
    component: PageNotFound,
    page: 'Page not found',
    breadcrumb: { Home: '' },
  },
  {
    path: '/registeragency',
    component: AnonymousAgency,
    page: 'Agency Registration',
    breadcrumb: { Home: '' },
  },
  {
    path: '/document/:msg/:id/:docId',
    component: DownloadErrorModal,
    page: 'Document Error Modal',
    breadcrumb: { Home: '' },
  },
  {
    path: '/welcome/:accountId',
    component: MailValidator,
    page: 'Mail Validation',
    breadcrumb: { Home: '' },
  },
  {
    path: '/limited/bids/:bidId/:Tab',
    component: LimitedBidsDetails,
    page: 'Limited Bid Details',
    breadcrumb: { Home: '' },
    shouldIndex: true,
  },
  {
    path: '/agencies/:StateName/:agencyName/procurement-opportunities/:Id',
    component: AgencyLanding,
    page: 'Limited Agency Landing',
    breadcrumb: { Home: '' },
    shouldIndex: true,
  },
  {
    path: '/wapp/registration',
    component: WappRegistration,
    page: 'Wapp Registration',
    breadcrumb: { Home: '' },
  },
  {
    path: '/passwordrecovery',
    component: AccountRecovery,
    page: 'Account Recovery',
    breadcrumb: { Home: '' },
    shouldIndex: true,
  },
  {
    path: '/accountrecovery',
    component: EmailRecovery,
    page: 'Account Recovery',
    breadcrumb: { Home: '' },
    shouldIndex: true,
  },
  {
    path: '/passwordreset/:ID',
    component: ResetPassword,
    page: 'Account Recovery',
    breadcrumb: { Home: '' },
  },
  {
    path: '/bidvisitlimitexceeded',
    component: BidLimitExceeded,
    page: 'BidLimitExceeded',
    breadcrumb: { Home: '' },
  },
  {
    path: '/registration',
    component: Registration,
    page: 'Registration',
    breadcrumb: { Home: '' },
    shouldIndex: true,
  },
  {
    path: '/registration/:memberId',
    component: Registration,
    page: 'Registration',
    breadcrumb: { Home: '' },
  },
  {
    path: '/aspagency',
    component: ASPAgency,
    page: 'ASP AGENCIES',
    breadcrumb: { Home: '' },
  },
  // Browse bids - SEO needs to optionally handle URL params for state / metro / cc group / cc category
  // TODO - remove long-hand routes, configure with optional path params
  //      - v5.2 of react - router doesn't seem to recognize the `/:param?' syntax.
  {
    path: '/browse-bids',
    component: BrowseBids,
    page: 'Browse Bids',
    breadcrumb: { Home: '' },
    shouldIndex: true,
  },
  {
    path: '/browse-bids/groups/:seoGroup',
    component: BrowseBids,
    page: 'Browse Bids',
    breadcrumb: { Home: '' },
    shouldIndex: true,
  },
  {
    path: '/browse-bids/groups/:seoGroup/categories/:seoCategory',
    component: BrowseBids,
    page: 'Browse Bids',
    breadcrumb: { Home: '' },
    shouldIndex: true,
  },
  {
    path: '/browse-bids/states/:seoState',
    component: BrowseBids,
    page: 'Browse Bids',
    breadcrumb: { Home: '' },
    shouldIndex: true,
  },
  {
    path: '/browse-bids/states/:seoState/metro-areas/:seoMetroArea',
    component: BrowseBids,
    page: 'Browse Bids',
    breadcrumb: { Home: '' },
    shouldIndex: true,
  },
  {
    path: '/browse-bids/states/:seoState/groups/:seoGroup',
    component: BrowseBids,
    page: 'Browse Bids',
    breadcrumb: { Home: '' },
    shouldIndex: true,
  },
  {
    path: '/browse-bids/states/:seoState/groups/:seoGroup/categories/:seoCategory',
    component: BrowseBids,
    page: 'Browse Bids',
    breadcrumb: { Home: '' },
    shouldIndex: true,
  },
  {
    path: '/browse-bids/states/:seoState/metro-areas/:seoMetroArea/groups/:seoGroup',
    component: BrowseBids,
    page: 'Browse Bids',
    breadcrumb: { Home: '' },
    shouldIndex: true,
  },
  {
    path: '/browse-bids/states/:seoState/metro-areas/:seoMetroArea/groups/:seoGroup/categories/:seoCategory',
    component: BrowseBids,
    page: 'Browse Bids',
    breadcrumb: { Home: '' },
    shouldIndex: true,
  },
  {
    path:'/esn/devcheck',
    component: DevCheck,
    page:"ESN BROWSING BIDS",
    breadcrumb:{ Home: ''},
    shouldIndex: true,
  },
  {
    path:'/esn/login/:token/:bidId',
    component: ESNLogin,
    page:"ESN BROWSING BIDS",
    breadcrumb:{ Home: ''},
    shouldIndex: true,
  },
];
