import { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import {
  DSCheckField,
  DSFileField,
  DSSelectField,
  DSTextField,
} from '@demandstar/components/fields';
import { useFileInput } from '@demandstar/components/inputs/file/useFileInput';

import * as texts from './AddDocument.texts';
import {
  AwardeeContact,
  ContractDetails,
  ContractStatus,
} from 'src/features/contract-management/contract-management.d';
import { compareObjectsByKey } from 'src/utils';
import { SelectDocumentTypes } from '../document-types';
import { useContractDetails } from 'src/features/contract-management/useContractDetails';
import { useContractDocuments } from '../useContractDocuments';
import { useContractSearch } from 'src/features/contract-management/search';

export const AddContractDocumentForm = () => {
  const { contractDetails } = useContractDetails();
  const contractId = contractDetails?.id;
  const { validateFileSize, validateFileType } = useFileInput();
  const { documentUploadProgress } = useContractDocuments();
  const [supplierOptions, setSupplierOptions] = useState<AwardeeContact[]>(
    contractDetails?.awardeeContacts || [],
  );
  const { allContracts } = useContractSearch();
  const [selectedId, setSelectedId] = useState(contractId);
  const { control ,setValue} = useFormContext();
  const formContract = useWatch<ContractDetails>({ name: 'contract', control: control } as any);

  const filteredContracts = useMemo(() => {
    return contractId
      ? []
      : allContracts
          .filter(
            contract =>
              contract.status === ContractStatus.Active || contract.status === ContractStatus.Draft,
          )
          .sort(compareObjectsByKey('name'));
  }, [allContracts, contractId]);

  useEffect(() => {
    const id = contractId || formContract?.id;
    if (id && id !== selectedId) {
      if (!contractDetails) {
        /* istanbul ignore else */
        if (allContracts.length) {
          setSelectedId(id);
          setSupplierOptions(allContracts?.find(c => c.id === id)?.awardeeContacts || []);
        }
      } else {
        setSelectedId(id);
        setSupplierOptions(contractDetails?.awardeeContacts || []);
      }
    }
  }, [allContracts, contractDetails, contractId, formContract?.id, selectedId]);

  return (
    <>
      <DSFileField
        dataTestId={'add-document-form.file'}
        label={texts.chooseFile}
        name={'file'}
        rules={{
          validate: {
            documentSizeSupported: validateFileSize,
            documentTypeSupported: validateFileType,
          },
        }}
        uploadProgress={documentUploadProgress}
      />
      <DSTextField
        optional
        dataTestId={'add-document-form.filename'}
        label={texts.formLabels.filename}
        message={texts.formMessages.filename}
        name='fileName'
      />
      {!contractId && (
        <DSSelectField
          dataTestId={'add-document-form.contract'}
          label={texts.formLabels.contract}
          message={texts.formMessages.contract}
          name='contract'
          labelField='name'
          valueField='id'
          options={filteredContracts}
        />
      )}
      {supplierOptions && supplierOptions?.length > 0 && (
      <Controller
        name="supplier"
        control={control}
        render={({ field }) => (
          <DSSelectField
            dataTestId='add-document-form.supplier'
            isClearable
            label={texts.formLabels.supplier}
            message={texts.formMessages.supplier}
            name='supplier'
            optional
            labelField='name'
            valueField={false}
            options={supplierOptions}
            onSelect={(value) => {
              setValue('supplier',value);
              field.onChange(value);
            }}
          />
        )}
      />
    )}
      <SelectDocumentTypes />
      <DSCheckField label={texts.formLabels.public} name='public' />
    </>
  );
};
