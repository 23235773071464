import { ChoiceLabel, ChoiceLabelProps } from '../../assets/ChoiceStyles';
import { DataTestId, Inactive } from '../../types';
import { BaseCheckbox } from './DSCheckbox.styles';
import { InvisibleInput } from '../../assets/CommonStyles';
import { onEnter } from '../../utils';

// TODO: Make more accessible.
// TODO: Create DSCheckboxGroup to be used in react-hook-form forms.

export type DSCheckboxProps = Inactive &
  ChoiceLabelProps &
  DataTestId & {
    checked: boolean;
    indeterminate?: boolean;
    label: string;
    onClick: () => void;
    id?: string;
    name: string;
    style?:any;
  };

/**
 * @prop {boolean} checked - the checkbox is true
 * @prop {string} label - the description of the checkbox
 * @prop {() => void} onClick - updates the value
 * @prop {boolean=} inactive - prevents onClick from firing
 * @prop {boolean=} indeterminate - displays a - to indicate neither true nor false
 */
export const DSCheckbox = (props: DSCheckboxProps): JSX.Element => {
  const {
    checked,
    dataTestId,
    inactive,
    id,
    name,
    onClick,
    label,
    indeterminate,
    inlineWithInput,
    style,
  } = props;

  function click() {
    if (!inactive) {
      onClick();
    }
  }

  return (
    <ChoiceLabel
      inactive={inactive}
      data-testid={name || dataTestId}
      inlineWithInput={inlineWithInput}
      style={style}
    >
      {label}
      <InvisibleInput
        readOnly={true}
        type='checkbox'
        onClick={click}
        id={id}
        name={name}
        checked={checked}
        onKeyDown={onEnter(click)}
      />
      <BaseCheckbox checked={checked} inactive={inactive} indeterminate={indeterminate} />
    </ChoiceLabel>
  );
};
