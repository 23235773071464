// TOREFACTOR

import { connect, ConnectedProps } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import { DSAlert } from '@demandstar/components/alert';
import { DSButton } from '@demandstar/components/button';
import { DSLink } from '@demandstar/components/link';
import { Status } from '@demandstar/components/constants';

import * as actionCreators from '../../../store/actions';
import {
  appstatetokenName,
  MemberType,
  notificationExpandCount,
  notificationInitialCount,
} from '../../../utils/constants';
import { commonLabels } from '../../../shared/constants';
import { constants } from '../../../utils/settings';
import { dismisstoastAllFn, toastFn } from '../../../utils/helpers';
import { getBidsTypes } from '../../../types/bids';
import { loadingMsg } from '../../../utils/texts';
import { memberinfoTypes } from '../../../types/dashboard';
import { nargsfnTypes } from '../../../types/functions';
import { NoResult } from '../../customcontrols';
import { ProductType } from 'src/types/products';
import { RequiredActionsDashboardPanel } from '../../../features/bids/management/RequiredActionsDashboardPanel';
import { sharedTypes } from '../../../types/shared';
import { useSetBreadcrumb } from 'src/shared/hooks';

import DashboardLeftPanel from '../../shared/dashboard/dashboardleftpanel';
import Notifications from './notifications';
import RightsideBottom from './rightsidebottom';
import RightsideTop from './rightsidetop';
import { useAuthContext } from 'src/auth/AuthProvider';
import { Authorization } from 'src/types';
import { useGetDashboardData } from '../../../query/queries/getDashboardData';
import { Loader } from 'src/components/customcontrols';
import { useGetDashboardTrackingData } from 'src/query/queries/getDashboardTrackingData';
import { useGetDashboardNetworkData } from 'src/query/queries/getDashboardNetworkData';
import { setDashboardStaticticsData } from 'src/query/helper/setDashboardStaticticsData';
import { AppContext } from 'src/global-context/AppContext';
import { ModalPopUp } from '../../common/modals';
import { EditCommodityCodesAndTags } from '../../shared/accountinfo/commoditycodes/editTagsAndCommodities';
import { useGetAccountInfoCommodity } from '../../../query/queries/getAccountInfoCommodity';

interface SupplierDashboardProps extends PropsFromRedux {
  accountinfo: any;
  getBids: getBidsTypes;
  getCanUpgrade: any;
  bidssummary: { canSupplierUpgradeAPICALL: boolean };
  getDashboardData: any;
  getdashboardNotifications: any;
  getQuotesList: any;
  getWatchedBidIds: any;
  history: any;
  memberInfo: any;
  memberinfo: memberinfoTypes;
  setBidDetails: any;
  setBidSummaryDetails: any;
  setQuotesDetails: any;
  setSharedDetails: nargsfnTypes;
  shared: sharedTypes;
  submitDisablenotification: any;
  getAccountInfo: any;
  getDashboardNetwork: any;
  getRelatedBids: any;
  getSiblingProducts: any;
  setAccountInfoDetails: any;
  trackAmplitudeUserActions: any;
}

interface commodityTypes {
  formattedCode: string | number;
  fullCode: string | number;
  commodityDescription: string;
}

interface Product {
  productType: string;
  productId: number;
}

function selectPid(products: Product[] = []): number | null {
  const product = products.find(product => product.productType === ProductType.FreeAgency);
  if (product) {
    return product.productId;
  } else {
    return null;
  }
}

function renderRequiredActionsListPanel() {
  return <RequiredActionsDashboardPanel listLength={5} />;
}

function SupplierDashboardComponent(props: SupplierDashboardProps) {
  const {
    getSiblingProducts,
    getRelatedBids,
    memberInfo,
    memberinfo,
    getdashboardNotifications,
    submitDisablenotification,
    setBidSummaryDetails,
    shared,
    history,
    setSharedDetails,
    getCanUpgrade,
    getWatchedBidIds,
    bidssummary,
    accountinfo,
    trackAmplitudeUserActions,
    setBidDetails,
    setQuotesDetails,
    getQuotesList,
    getBids,
  } = props;

  const { auth } = useAuthContext();
  const {
    fetchDashboardData,
    fetchDashboardTrackingData,
    showCommodityPopUpinDashboard,
    RefetchDashboardData,
    RefetchDashboardTrackingData,
    changeShowCommodityPopupinDashboard,
  } = useContext(AppContext);
  const { memberType } = auth || ({} as Authorization);

  const { canSupplierUpgradeAPICALL = true } = bidssummary;
  const {
    dashboardnotifications = [],
    dashboardnotifications_internalLoader,
  } = shared;
  const { mn = '', mi = '', mtn: memberTypeName } = memberinfo;
  const products: Product[] = accountinfo?.accountinfo?.products;
  const pid: number | null = selectPid(products);

  let statistics = {};
  let dashboardData = [];

  useSetBreadcrumb({
    page: 'Dashboard',
    altname: 'Dashboard',
    title: 'Dashboard',
    name: 'Dashboard',
    menuactive: 'dashboard',
    component: '',
  });

  const {
    isSuccess: dashboardSuccess,
    isError: dashboardDataError,
    isFetching: dashboardDataFetching,
    data: responseData,
    refetch: refetchDashboardData,
  } = useGetDashboardData({});

  const {
    isSuccess: dashboardTrackingSuccess,
    isError: dashboardTrackingError,
    data: responseTrackingData,
    refetch: refetchDashboardTracking,
  } = useGetDashboardTrackingData({});

  const {
    isSuccess: dashboardNetworkSuccess,
    isError: dashboardNetworkError,
    isFetching: dashboardNetworkFetching,
    data: responseNetworkData,
    refetch: refetchDashboardNetwork,
  } = useGetDashboardNetworkData({});

  const {
    data: myCommodityData,
    isFetching:FetchingDashboardCommodities,
    refetch: refetchGetAccountInfoCommodity,
  } = useGetAccountInfoCommodity();

  useEffect(() => {
    if (fetchDashboardData) {
      refetchDashboardData();
      RefetchDashboardData(false);
    }
  }, [refetchDashboardData, RefetchDashboardData, fetchDashboardData]);

  useEffect(() => {
    if (fetchDashboardTrackingData) {
      refetchDashboardTracking();
      RefetchDashboardTrackingData(false);
    }
  }, [refetchDashboardTracking, RefetchDashboardTrackingData, fetchDashboardTrackingData]);

  if (dashboardSuccess && dashboardTrackingSuccess && dashboardNetworkSuccess) {
    dashboardData = responseData?.data?.result;
    const data = setDashboardStaticticsData(responseData, responseTrackingData);
    statistics = data?.statistics;
  } // if any api fails then this below toast appears.
  else if (dashboardDataError || dashboardTrackingError || dashboardNetworkError) {
    toastFn('error', 'Failed to retrieve dashboard data.Please try later. ', true);
  }

  useEffect(() => {
    if (!pid || memberTypeName !== 'Free Agency') return;
    getSiblingProducts({ productId: pid });
  }, [getSiblingProducts, pid, memberTypeName]);

  useEffect(() => {
    if (memberTypeName !== 'Free Agency') return;
    getRelatedBids();
  }, [getRelatedBids, memberTypeName]);

  useEffect(() => {
    getWatchedBidIds();
  }, [getWatchedBidIds]);

  useEffect(() => {
    if (auth?.memberType !== MemberType.BasicSupplier) {
      getdashboardNotifications({});
    }
  }, [auth, getdashboardNotifications]);

  useEffect(() => {
    const l_storage = localStorage.getItem(appstatetokenName) || '';
    if (l_storage) {
      const { auth: localauth } = (l_storage && JSON.parse(l_storage)) || {};
      if (localauth && localauth.opi) {
        if (mi && mi !== localauth.mi) {
          memberInfo({ isCurrentMember: true });
        }
      }
    }
  }, [memberInfo, mi, mn]);

  useEffect(() => {
    dismisstoastAllFn();
  }, []);

  const commoditieslist: commodityTypes[] | [] =
  myCommodityData?.data?.result?.commodityCodes?.length > 0 ?  myCommodityData?.data?.result?.commodityCodes?.slice(0, 5) : [];

  const [notificationlistCount, setNotificationlistCount] = useState(notificationInitialCount);

  const handleCloseCommodityPopUp =()=>{
    changeShowCommodityPopupinDashboard(false);
  };

  const commodityCodeAlert = {
    button: (
      <DSButton
        onClick={() => {
          if (!FetchingDashboardCommodities) {
            history.push('/suppliers/account/commoditycodes/edit');
            setSharedDetails({ pathfromdashboard: true });
          }
        }}
      >
        Choose commodity codes
      </DSButton>
    ),
    handleClick: () =>
      window.open(
        ' https://network.demandstar.com/agency-support/supplier-add-commodity-codes/',
        '_blank',
      ),
    header: 'Choose commodity codes',
    message:
      "Commodity codes are how we pair your business with governments looking to work with you. Choose a few so we can help you find bids! It's okay if you're not exactly sure what codes to pick; you can always change this later.",
    link: 'Learn about commodity codes',
  };

  const CommoditieslistComponent = () => {
    return (
      <div className='commodityWrapper mt-3 clearfix p-4'>
        <h3 className='lineHR clearfix'>My Commodity Codes ({myCommodityData?.data?.result?.commodityCodes?.length})</h3>
        {commoditieslist.length > 0 ? (
          <>
            {commoditieslist.map((commodity: commodityTypes, id: number) => (
              <p key={id} className='mb-3'>
                <i className='staticTxt'>[{commodity.formattedCode || commodity.fullCode}]</i>
                {commodity.commodityDescription}
              </p>
            ))}
            {parseInt(`${myCommodityData?.data?.result?.commodityCodes?.length}`) > 5 ? (
              <div
                data-testid='view.more.commodity.codes'
                title={commonLabels.viewMore}
                className='float-right staticLink'
                onClick={() => history.push('/suppliers/account/commoditycodes')}
              >
                {`${commonLabels.viewMore}`} <i className='mdi mdi-launch' />
              </div>
            ) : null}
          </>
        ) : (
          <NoResult
            onClick={() => {
              if (!FetchingDashboardCommodities) {
                history.push('/suppliers/account/commoditycodes/edit');
                setSharedDetails({ pathfromdashboard: true });
              }
            }}
            message={
              FetchingDashboardCommodities
                ? loadingMsg
                : 'Select Commodity Codes to receive bid notifications.'
            }
            pClass='staticLink'
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    if (canSupplierUpgradeAPICALL) {
      getCanUpgrade();
      setBidSummaryDetails({ canSupplierUpgradeAPICALL: false });
    }
  }, [canSupplierUpgradeAPICALL, getCanUpgrade, setBidSummaryDetails]);

  /* const toggleAgencySelection = useCallback(() => {
    setAccountInfoDetails({ agencySelection: !agencySelection });
  }, [setAccountInfoDetails, agencySelection]); */

  return (
    <div className='container dashboardWrapper '>
      <div className='row justify-content-center'>
        <div className='col-12 col-lg-3 mb-3 mb-lg-0'>
          <div className='sideBarSpacing dashboardSidebar'>
            <DashboardLeftPanel
              {...props}
              shared={shared}
              getDashboardBidsList={actionCreators.getDashboardBidsList}
              getDashboardQuotesList={actionCreators.getDashboardQuotesList}
              history={history}
              getBids={getBids}
              setBidDetails={setBidDetails}
              getQuotesList={getQuotesList}
              setQuotesDetails={setQuotesDetails}
              dashboardData={dashboardData}
              statistics={statistics}
            />
          </div>
        </div>
        <div className='col-12 col-lg-9'>
          <div className='colWrapper'>
            <h2 className='arrowWrapper'>{mn}</h2>
            {memberType !== MemberType.BasicSupplier && myCommodityData?.data?.result?.commodityCodes?.length === 0 && (
              <DSAlert
                button={commodityCodeAlert.button}
                header={commodityCodeAlert.header}
                link={
                  <DSLink onClick={commodityCodeAlert.handleClick}>
                    {commodityCodeAlert.link}
                  </DSLink>
                }
                type={Status.Warning}
              >
                {commodityCodeAlert.message}
              </DSAlert>
            )}
            <div className='row'>
              {auth?.memberType === MemberType.BasicSupplier ? (
                <>
                  <div className='col-lg-12'>
                    <div className='row'>
                      <div className='col-lg-6'>
                        <RightsideTop
                          {...props}
                          getBids={getBids}
                          setBidDetails={setBidDetails}
                          getQuotesList={getQuotesList}
                          setQuotesDetails={setQuotesDetails}
                          dashboardData={dashboardData}
                        />
                      </div>
                      <div className='col-12 col-lg-6 mt-4 mt-lg-0'>
                        <div className='notificationWrapper d-flex flex-column justify-content-center px-4'>
                          {renderRequiredActionsListPanel()}

                          <h3 className='text-center'>Upgrade Your Subscription</h3>
                          <ul>
                            <li>Free bid documents</li>
                            <li>Instant email notifications for future bid opportunities</li>
                            <li>Customized notifications tailored to your business</li>
                            <li>Free and Paid Subscriptions available</li>
                          </ul>
                          <Link
                            className='bttn-accent w-100 mb-2 btn-block text-center'
                            title='Upgrade Subscription'
                            rel='noopener noreferrer'
                            to='/suppliers/plans'
                            onClick={() => {
                              trackAmplitudeUserActions({
                                title: 'upgrade - click basic upgrade',
                                desc: 'Click button on basic user dashboard',
                                data: {
                                  Source: `${constants.networkUrl}/suppliers/dashboard`,
                                },
                              });
                            }}
                          >
                            Upgrade Subscription
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    {!dashboardNetworkFetching && !dashboardDataFetching ? (
                      <RightsideBottom dashboardnetworkData={responseNetworkData?.data?.result} />
                    ) : (
                      <Loader loading={dashboardDataFetching} />
                    )}
                  </div>
                </>
              ) : (
                <>
                  {!dashboardNetworkFetching && !dashboardDataFetching ? (
                    <div className='col-lg-6'>
                      <RightsideTop
                        {...props}
                        getBids={getBids}
                        setBidDetails={setBidDetails}
                        getQuotesList={getQuotesList}
                        setQuotesDetails={setQuotesDetails}
                        dashboardData={dashboardData}
                      />
                      <RightsideBottom dashboardnetworkData={responseNetworkData?.data?.result} />
                    </div>
                  ) : (
                    <Loader loading={dashboardDataFetching} />
                  )}

                  <div className='col-lg-6 mt-3 mt-lg-0'>
                    <div className='notificationWrapper clearfix text-left p-4'>
                      {renderRequiredActionsListPanel()}

                      <h3 className='lineHR'>Notifications ({dashboardnotifications.length})</h3>
                      {dashboardnotifications.length > 0 ? (
                        <>
                          {dashboardnotifications
                            .map((item: any, index: number) => {
                              return (
                                <Notifications
                                  history={history}
                                  key={index}
                                  notification={item}
                                  submitDisablenotification={submitDisablenotification}
                                  setSharedDetails={setSharedDetails}
                                />
                              );
                            })
                            .slice(0, notificationlistCount)}
                          {notificationlistCount < dashboardnotifications.length ? (
                            <>
                              <span
                                className='float-right staticLink clearfix'
                                title='Show More'
                                onClick={() =>
                                  setNotificationlistCount(
                                    notificationlistCount + notificationExpandCount,
                                  )
                                }
                              >
                                Show More <i className='mdi mdi-chevron-double-down' />
                              </span>
                              <p className='clearfix' />
                            </>
                          ) : (
                            <>
                              {dashboardnotifications.length > 5 &&
                              notificationlistCount >= dashboardnotifications.length ? (
                                <>
                                  <span
                                    className='float-right staticLink clearfix'
                                    title='Collapse All'
                                    onClick={() =>
                                      setNotificationlistCount(notificationInitialCount)
                                    }
                                  >
                                    Collapse All <i className='mdi mdi-chevron-double-up' />
                                  </span>
                                  <p className='clearfix' />
                                </>
                              ) : null}
                            </>
                          )}
                        </>
                      ) : (
                        <NoResult
                          message={
                            dashboardnotifications_internalLoader
                              ? loadingMsg
                              : 'No Notifications Available'
                          }
                        />
                      )}
                      {dashboardnotifications.length <= 2 ? <CommoditieslistComponent /> : null}
                    </div>
                  </div>
                </>
              )}
            </div>
            {auth?.memberType === MemberType.BasicSupplier ? null : (
              <div className='row'>
                <div className='col-12'>
                  {dashboardnotifications.length > 2 ? <CommoditieslistComponent /> : null}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <ModalPopUp
        size='lg'
        title={'Upgrade to free agency'}
        closeModal={toggleAgencySelection}
        isOpen={agencySelection === true}
        backdrop='static'
      >
        <AgencySelection
          {...props}
          userType={opi ? 'ops' : 'supplier'}
          toggleAgencySelection={toggleAgencySelection}
          products={[]}
          pageFor='dashboard'
        />
      </ModalPopUp> */}
      <ModalPopUp
        size='xxl'
        title={'Refine your Bid Notifications'}
        closeModal={()=>
          handleCloseCommodityPopUp()
        }
        isOpen={ showCommodityPopUpinDashboard  && myCommodityData?.data?.result?.commodityCodes?.length === 0
        }
        backdrop='static'
      >
  <EditCommodityCodesAndTags setShowEditcommodityPopUp={handleCloseCommodityPopUp} />
  </ModalPopUp>
    </div>
  );
}

const connector = connect(
  (state: any) => ({
    memberinfo: state.memberinfo,
    shared: state.shared,
    bidssummary: state.bidssummary,
    accountinfo: state.accountinfo,
    subscriptions: state.subscriptions,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const SupplierDashboard = connector(SupplierDashboardComponent);
