import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';

import { Assert, formatDollar, formatPhone } from '@demandstar/components/utils';
import { DSCheckbox, DSSelect } from '@demandstar/components/inputs';
import {
  DSCheckField,
  DSDateField,
  DSDollarField,
  DSEmailField,
  DSPhoneField,
  DSRadioGroupField,
  DSSelectField,
  DSTextAreaField,
  DSTextField,
} from '@demandstar/components/fields';
import { FlexContainer, H4 } from '@demandstar/components/styles';
import { Status } from '@demandstar/components/constants';

import { Alert, useAlert } from 'src/components/common/alert';
import {
  AwardeeContact,
  AwardeeContactDraft,
  ContractDetails,
  ContractStatus,
} from 'src/features/contract-management/contract-management.d';
import { ContractWizardId, useContractWizard } from '../useContractWizard';
import { addContractDuration } from 'src/features/contract-management/contract-management.helpers';
import { commonLabels } from 'src/shared/constants';
import { contractDetailsText } from './VerifyContractDetails.texts';
import { ContractLimitAlert } from '../../components/ContractLimitAlert';
import { ContractWizardButtons } from '../ContractWizardButtons';
import { hasValue } from 'src/utils/helpers';
import { LoadableWrapper } from 'src/components/common/loading/LoadableWrapper';
import { memberInfoSelector } from 'src/store/recoil/memberState';
import { paths } from 'src/features/contract-management/ContractManagement.paths';
import { TermLength } from './TermLength';
import { tryCatchLog } from 'src/utils/errors';
import { useBidAwardees } from 'src/store/recoil/bid-awardees';
import { useContractDetails } from '../../../useContractDetails';
import { useContractSearch } from 'src/features/contract-management/search';
import { useIdFromRoute } from 'src/shared/hooks/useIdFromRoute';
import { useRecoilValueLoadable } from 'recoil';
import { useRouter } from '../../../../../shared/hooks/useRouter';

type ContractSummaryForm = ContractDetails;

const errorAlertId = 'verify-contract-details-error';

/** [] of numbers 1-9 */
const termsArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

export const VerifyContractDetails = () => {
  // Hooks
  const { history, location, routerParams } = useRouter();
  const { contractDetails, contractDetailsLoadable, saveContractDetails } = useContractDetails();
  const { bid } = useContractWizard();
  const { bidAwardeesLoadable } = useBidAwardees();

  const { bidId } = useIdFromRoute();
  const { contractLimitExceeded, contractLimitOK, search } = useContractSearch();

  const [existingContract] = useMemo(() => {
    if (bidId) return search({ bidId: bidId });
    return [];
  }, [bidId, search]);

  const memberInfo = useRecoilValueLoadable(memberInfoSelector);
  const errorAlert = useAlert(errorAlertId);

  const methods = useForm<ContractSummaryForm>({
    mode: 'onTouched',
  });
  const { getValues, register, setValue, trigger, unregister, watch } = methods;


  const terms = watch('terms') || [];
  const termsRemaining = Math.max(terms.length - 1, 0);
  const startDate = watch('startDate');

  const awardees: AwardeeContactDraft[] = useMemo(() => {
    if (contractDetails) {
      return contractDetails.awardeeContacts;
    }
    if (hasValue(bidAwardeesLoadable)) {
      return bidAwardeesLoadable.contents.map(awardee => {
        return {
          amount: awardee.amount,
          name: awardee.supplierName,
          memberId: awardee.awardedToMemberId,
          email: awardee.email,
        } as AwardeeContact;
      });
    }
    return [];
  }, [bidAwardeesLoadable, contractDetails]);

  const submitContactDetails = useCallback(async () => {
    errorAlert.hide();
    const valid = await trigger();
    const values = getValues();

    if (valid) {
      return await tryCatchLog(
        async () => {
          const contract = await saveContractDetails({
            ...contractDetails,
            ...values,
            awardeeContacts: awardees,
            allowBuyOff: !!values.allowBuyOff,
            bidId: bid?.bidId,
          });
          history.replace(`/buyers/contract/edit/${contract?.id}`);
          return true;
        },
        'VerifyContractDetails => saveContractDetails',
        () => {
          errorAlert.show();
          return false;
        },
      );
    }

    return false;
  }, [
    awardees,
    bid?.bidId,
    contractDetails,
    errorAlert,
    getValues,
    history,
    saveContractDetails,
    trigger,
  ]);
  const addTerms = useCallback(
    (numTerms: number) => {
      const terms = watch('terms') || [];
      const baseTerm = terms[terms.length - 1]?.duration || 'P1D';

      for (let i = terms.length; i < numTerms; i++) {
        register(`terms.${i}.duration`);
        setValue(`terms.${i}.duration`, baseTerm);
      }
    },
    [register, setValue,watch],
  );

  const updateTerms = useCallback(
    (numTerms: number) => {
      const terms = watch('terms') || [];
      if (numTerms > termsRemaining) {
        addTerms(numTerms);
      } else if (numTerms <= termsRemaining) {
        const currentTermDuration = terms[terms.length - 1]?.duration;
        unregister('terms');
        for (let i = 1; i <= Math.min(numTerms, 10); i++) {
          register(`terms.${i - 1}.duration`);
          setValue(`terms.${i - 1}.duration`, currentTermDuration);
        }
      }
    },
    [addTerms, termsRemaining,watch,register, setValue, unregister],
  );

  const handleTermSelection = useCallback(
    (termNum: number) => {
      return updateTerms(termNum + 1);
    },
    [updateTerms],
  );

  // This useEffect sets up the initial data based on the route
  // Handles create (new), import, and edit data import to the form.
  useEffect(() => {
    // Wizard Edit Mode
    if (location.pathname.includes('/buyers/contract/edit')) {
      // Load existing contract details

      if (contractDetails) {
        /* istanbul ignore else */
        if (contractDetails.name) setValue('name', contractDetails.name);
        /* istanbul ignore else */
        if (contractDetails.solicitationId)
          setValue('solicitationId', contractDetails.solicitationId);
        /* istanbul ignore else */
        if (contractDetails.amount) setValue('amount', contractDetails.amount);
        /* istanbul ignore else */
        if (contractDetails.startDate) setValue('startDate', contractDetails.startDate);
        /* istanbul ignore else */
        if (contractDetails.scopeOfWork) setValue('scopeOfWork', contractDetails.scopeOfWork);
        /* istanbul ignore else */
        if (contractDetails.terms.length)
          setValue('terms.0.duration', contractDetails.terms[0]?.duration);
        /* istanbul ignore else */
        if (contractDetails.endDate) setValue('endDate', contractDetails.endDate);
        /* istanbul ignore else */
        if (contractDetails.agencyContact?.name)
          setValue('agencyContact.name', contractDetails.agencyContact.name);
        /* istanbul ignore else */
        if (contractDetails.agencyContact?.phone)
          setValue('agencyContact.phone', formatPhone(contractDetails.agencyContact.phone));
        /* istanbul ignore else */
        if (contractDetails.agencyContact?.email)
          setValue('agencyContact.email', contractDetails.agencyContact.email);
        /* istanbul ignore if */
        if (contractDetails.isPhonePublic) setValue('isPhonePublic', contractDetails.isPhonePublic);
        /* istanbul ignore if */
        if (contractDetails.isEmailPublic) setValue('isEmailPublic', contractDetails.isEmailPublic);
        setValue('allowBuyOff', !!contractDetails.allowBuyOff);
        const termCount = contractDetails.terms.length;
        for (let i = 1; i < termCount; i++) {
          const term = contractDetails.terms[i];
          register(`terms.${i}.duration`);
          setValue(`terms.${i}.duration`, term?.duration);
        }
      }
    } else {
      // Wizard Manual Create Mode
      Assert(!contractDetails, 'on import, contractDetails should be undefined.');
      setValue('terms.0.duration', 'P1W');
      setValue('startDate', dayjs());

      // Wizard Import Mode
      if (location.pathname.includes('/buyers/contract/import/bid')) {
        // Import - Bid Details
        if (bid) {
          // if checks necessary to prevent bid values overwriting values in contractDetails
          setValue('name', bid.bidName);
          setValue('solicitationId', bid.bidNumber);
          setValue('scopeOfWork', bid.scopeOfWork);

          setValue('agencyContact.name', bid.bidWriter);
          setValue('agencyContact.email', memberInfo.contents.eml);
          setValue('agencyContact.phone', formatPhone(memberInfo.contents.p));
        }

        // Import - Bid Awardee Total
        if (bidAwardeesLoadable.state === 'hasValue' && bidAwardeesLoadable.contents) {
          /* istanbul ignore else */
          if (bidAwardeesLoadable.contents.length === 1) {
            setValue('amount', formatDollar(bidAwardeesLoadable.contents[0].amount));
          }
        }
      }
    }
  }, [
    bid,
    bidAwardeesLoadable.contents,
    bidAwardeesLoadable.state,
    contractDetails,
    location,
    memberInfo,
    register,
    routerParams.contractId,
    setValue,
  ]);

  useEffect(() => {
    // keep contractDate in sync
    const terms = watch('terms') || [];
    if (startDate && terms) {
      let calculatedEndDate = startDate;
      for (const term of terms) {
        // this bit is to wash over some small loading time with RHF.
        Assert(term?.duration, 'All terms must have a duration.', 'VerifyContractDetails');
        if (term?.duration) {
          calculatedEndDate = addContractDuration(calculatedEndDate, term?.duration);
        }
      }
      setValue('endDate', calculatedEndDate);
    }
  }, [setValue, startDate,watch]);

  /** validate that there is not an existing contract for this bid */
  useEffect(() => {
    if (existingContract) {
      history.push(
        existingContract.status === ContractStatus.Draft
          ? paths.navigation.editDraft(existingContract.id)
          : paths.navigation.contractDetails(existingContract.id),
      );
    }
  }, [existingContract, history]);

  function toggleExtendedTerms() {
    if (termsRemaining) {
      // 1 term is base period only, with 0 extensions.
      updateTerms(1);
    } else {
      // 2 terms is base period + 1 extension.
      updateTerms(2);
    }
  }

  return (
    <LoadableWrapper loadable={contractDetailsLoadable}>
      <Alert id={errorAlertId} header={contractDetailsText.error} type={Status.Error} allowDismiss>
        {contractDetailsText.errorMessage}
      </Alert>
      {!contractLimitOK ? (
        <FlexContainer>
          <ContractLimitAlert />
        </FlexContainer>
      ) : null}
      <FormProvider {...methods}>
        <DSTextField
          dataTestId='name'
          name='name'
          label={contractDetailsText.name}
          message={contractDetailsText.nameMessage}
        />
        <DSTextField
          dataTestId='solicitationId'
          name='solicitationId'
          label={contractDetailsText.solicitationId}
          message={contractDetailsText.solicitationIdMessage}
        />
        <DSDollarField
          dataTestId='amount'
          name='amount'
          label={contractDetailsText.amount}
          optional
        />
        <DSDateField
          dataTestId='startDate'
          name='startDate'
          label={contractDetailsText.startDate}
        />
        <DSTextAreaField
          dataTestId='scopeOfWork'
          name='scopeOfWork'
          label={contractDetailsText.scopeOfWork}
        />
        <TermLength label={contractDetailsText.basePeriod} name='terms.0.duration' />
        <DSCheckbox
          name={'multipleTerms'}
          label={contractDetailsText.multipleTerms}
          onClick={toggleExtendedTerms}
          checked={!!termsRemaining}
        />
        <DSSelect
          dataTestId='termsRemaining'
          name='termsRemaining'
          label={contractDetailsText.termsRemaining}
          message={contractDetailsText.termsRemainingMessage}
          options={termsArray}
          value={termsRemaining}
          onSelect={handleTermSelection}
        />
        {terms.map((x, i) =>
          i > 0 ? (
            <TermLength
              label={contractDetailsText.termsRemainingLabel(i)}
              name={`terms.${i}.duration`}
              key={i}
            />
          ) : (
            <Fragment key={i}></Fragment>
          ),
        )}

        <DSDateField
          dataTestId='endDate'
          name='endDate'
          label={contractDetailsText.endDate}
          inactive
        />
        <H4>{contractDetailsText.primaryAgencyContact}</H4>
        <DSTextField
          dataTestId='contactName'
          name='agencyContact.name'
          label={contractDetailsText.contactName}
          message={contractDetailsText.contactNameMessage}
        />
        <DSPhoneField
          dataTestId='contactPhone'
          name='agencyContact.phone'
          label={contractDetailsText.contactPhone}
        />
        <DSCheckField name='isPhonePublic' label={contractDetailsText.isPhonePublic} />
        <DSEmailField
          dataTestId='contactEmail'
          name='agencyContact.email'
          label={contractDetailsText.contactEmail}
        />
        <DSCheckField name='isEmailPublic' label={contractDetailsText.isEmailPublic} />
        <DSRadioGroupField
          label={contractDetailsText.otherAgencies[0]}
          message={contractDetailsText.otherAgencies[1]}
          dataTestId='allowBuyOff'
          name='allowBuyOff'
          optional
          options={[
            { label: commonLabels.yes, value: true },
            { label: commonLabels.no, value: false },
          ]}
        />
        <ContractWizardButtons
          inactiveNext={contractLimitExceeded}
          nextWizardId={ContractWizardId.SupplierDetails}
          onSave={submitContactDetails}
          previousWizardId={ContractWizardId.None}
        /**
         * TODO: These introduced some bugs, so not worth regressing.
         * Still, I think below is the behavior we want.
         * where an invalid form prevents movement forward.
         */
        // inactiveNext={!isValid}
        // onInactiveClick={trigger}
        />
      </FormProvider>
    </LoadableWrapper>
  );
};
