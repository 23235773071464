import { useCallback, useMemo, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { DSSelectField } from '@demandstar/components/fields';

import * as texts from './ManageDocumentTypes.texts';
import { AddDocumentFormValues } from '../add-document/AddDocument.helpers';
import { compareObjectsByKey } from 'src/utils';
import { ContractDocumentType } from '../../../contract-management.d';
import { ManageDocumentTypesForm } from './ManageDocumentTypesForm';
import { useContractDocuments } from '../useContractDocuments';

export const SelectDocumentTypes = () => {
  const { contractDocumentTypes } = useContractDocuments();
  const { setValue, control } = useFormContext<AddDocumentFormValues>();
  const addNew = useMemo(
    () => ({
      description: 'add',
      isDeleted: false,
      title: texts.addDocumentType,
    }),
    [],
  );
  const documentTypes = useMemo(
    () => [
      ...contractDocumentTypes.filter(t => !t.isDeleted).sort(compareObjectsByKey('title')),
      addNew,
    ],
    [addNew, contractDocumentTypes],
  );
  const [addingDoc, setAddingDoc] = useState(false);
  const [newDocTypeTitle, setNewDocTypeTitle] = useState<string | undefined>(undefined);

  const handleCreateDocType = useCallback(
    (value?: string) => {
      setAddingDoc(true);
      setNewDocTypeTitle(value);
      setValue('documentType', addNew, { shouldValidate: true, shouldDirty: true });
    },
    [addNew, setValue],
  );

  const handleSelect = useCallback(
    (docType?: ContractDocumentType) => {
      if (!docType?.id) {
        handleCreateDocType();
      } else {
        setNewDocTypeTitle(undefined);
        setAddingDoc(false);
        setValue('documentType', docType, { shouldValidate: true, shouldDirty: true });
      }
    },
    [handleCreateDocType, setValue],
  );

  return (
    <>
      <Controller
        name="documentType"
        control={control}
        render={({ field }) => (
          <DSSelectField
            dataTestId={'select-contract-doc-type'}
            label={texts.documentType}
            name='documentType'
            labelField='title'
            valueField={false}
            options={documentTypes}
            onCreateOption={handleCreateDocType}
            onSelect={(value) => {
              handleSelect(value as ContractDocumentType);
              field.onChange(value);
            }}
            placeholder={texts.selectDocTypePlaceHolder}
          />
        )}
      />
      {addingDoc && <ManageDocumentTypesForm withinForm={true} defaultTitle={newDocTypeTitle} />}
    </>
  );
};
