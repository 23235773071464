// CRUD
export const create = '/Reminders/createorupdate';
export const get = (reminderId: string) => `/Reminders/getReminderById/:Id?Id=${reminderId}`;
export const update=(reminderId: string) => `/Reminders/dismissReminderById/:Id?Id=${reminderId}`;
export const remove = (reminderId: string) => `/Reminders/dismissReminderById/:Id?Id=${reminderId}`;

// Misc.
export const send = (reminderId: string) => `/Reminders/:Id?Id=${reminderId}/send`;
export const search = '/Reminders/search';

export const getPreviewPreferences = '/Reminders/preview';
export const setPreviewPreferences = '/Reminders/updatepreviewinfo';
