import { createClient, Provider as URQLProvider, cacheExchange, fetchExchange } from 'urql';
import { useEffect, useMemo, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { LayoutType } from '@demandstar/components/constants';

import { allowSEOIndex, clearStorageFn, disAllowSEOIndex } from '../utils/helpers';
import { Permission } from '../types/auth';
import { Default, LoggedIn } from '../components/customcontrols';
import {
  loginPreviousUrlName,
  loginPreviousUrlSearch,
  MemberType,
  supplierTypes,
} from '../utils/constants';
import { actionPayloadTypes } from '../types/actiontypedef';
import { useAuthContext } from '../auth/AuthProvider';
import { ComponentRoutes } from './ComponentRoutes';
import Configurations from '../settings';
import MaintananceSplash from '../components/common/maintanancesplash';
import { PageNotFound } from '../components/common/pagenotfound';
import { ReduxAction } from '../store/actions/utils';

//TODO can either of these `any`'s be updated?
interface AuthenticatedRouteTypes {
  component: any;
  //could be exact?: boolean
  exact: any;
  layout: LayoutType;
  opsUser: boolean;
  path: string;
  resetAuth: (payload?: actionPayloadTypes) => ReduxAction;
}

const AuthenticatedRoute = (authProps: AuthenticatedRouteTypes) => {
  const { component: Component, exact, opsUser, ...rest } = authProps;
  const { isAuthenticated, isTokenExpired, token, resetAuth } = useAuthContext();
  const [layout, setLayout] = useState(authProps.layout);

  const client = useMemo(() => {
    if (!isAuthenticated) {
      return null;
    } else {
      return createClient({
        url: 'https://dsstg-gateway.azurewebsites.net/graphql', // TODO - configure gql endpoint by .env values.
        fetchOptions: () => {
          return {
            headers: { authorization: token ? `Bearer ${token}` : '' },
          };
        },
        exchanges: [ cacheExchange, fetchExchange],
      });
    }
  }, [isAuthenticated, token]);

  useEffect(() => {
    disAllowSEOIndex();
  }, []);

  !layout && setLayout(LayoutType.None);

  return (
    <Route
      exact
      {...rest}
      render={props =>
        isAuthenticated ? (
          <LoggedIn layout={layout}>
            {client && (
              <URQLProvider value={client}>
                <Component {...props} />
              </URQLProvider>
            )}
            {!client && <Component {...props} />}
          </LoggedIn>
        ) : (
          <>
            {console.log('not logged in!')}
            {sessionStorage.setItem(loginPreviousUrlName, props.history.location.pathname)}
            {sessionStorage.setItem(loginPreviousUrlSearch, props.history.location.search)}
            {/* {(window.location.href = Configurations.REACT_APP_REDIRECT_HOME_URL)} */}
          </>
        )
      }
    />
  );
};

const DefaultRoute = ({ component: Component, ...rest }: any) => {
  useEffect(() => {
    if (rest.shouldIndex) {
      allowSEOIndex();
    } else {
      disAllowSEOIndex();
    }
  }, [rest.shouldIndex]);

  return (
    <Route
      exact
      {...rest}
      render={props => (
        <Default>
          <Component {...props} />
        </Default>
      )}
    />
  );
};

interface propsTypes {
  resetAuth: any;
}

const Routes = (props: propsTypes) => {
  const { auth, isAuthenticated } = useAuthContext();
  const { resetAuth } = props;
  console.log("auth", auth);
  const permissions = auth?.accountPermissions.replace(/ /g, '').split(',') as Permission[];
  let opsUser = false;

  let privateRoutes: any = [];
  if (isAuthenticated) {
    if (auth?.memberType === MemberType.AgencyBuyer) {
      privateRoutes = ComponentRoutes.buyer;
    } else if (supplierTypes.includes(auth?.memberType as any)) {
      privateRoutes = ComponentRoutes.supplier;
    }
  }

  if (privateRoutes.length === 0 && auth && auth?.token && permissions.includes(Permission.Ops)) {
    privateRoutes = ComponentRoutes.ops;
    opsUser = true;
  }

  return (
    <>
      {/*<Suspense fallback={<Suspenseloader />}>*/}
      <Switch>
        {ComponentRoutes.limited.map((item, index) => (
          <DefaultRoute
            key={index}
            path={item.path}
            shouldIndex={item.shouldIndex}
            exact
            component={item.component}
          />
        ))}
        {privateRoutes.map(
          (item: { path: string; component: any; layout: LayoutType }, index: number) => (
            <AuthenticatedRoute
              component={item.component}
              exact
              key={index}
              layout={item.layout}
              opsUser={opsUser}
              path={item.path}
              resetAuth={resetAuth}
            />
          ),
        )}
        <Route path='/maintanance' component={MaintananceSplash} />
        <Route path='*' component={PageNotFound} />
      </Switch>
      {/*</Suspense>*/}
    </>
  );
};

export default Routes;
