import React, { memo } from 'react';
import { Table } from 'reactstrap';
import { MemberType } from '../../../../../utils/constants';
import { loadingMsg } from '../../../../../utils/texts';
import {
  deprecatedCheckTimezoneCountDown,
  displayDateTime,
  formatPrice,
  formattedPhoneNumber,
} from '../../../../../utils/helpers';
import { bidssummaryParamType, ebidsParamType } from '../../../../../types/biddetail';
import { Buttons } from '../../../../customcontrols';
import { sharedParamType } from '../../../../../types/commoditycodes';
import { WizardPage, WizardStatus } from '../../../../../types/wizard';

import history from '../../../../../utils/history';
import NoResult from '../../../../customcontrols/noresult';
import ViewResponseHistory from '../../../../shared/bidsdetail/viewresponsehistory';
import { useAuthContext } from 'src/auth/AuthProvider';

const tdWidth = {
  width: '180px',
};

interface EbidSupplierDetailsProps {
  ebids?: ebidsParamType;
  shared?: sharedParamType;
  bidssummary?: bidssummaryParamType;
  setEbidDetails: any;
}

function EbidSupplierDetails(props: EbidSupplierDetailsProps) {
  const { ebids, shared, bidssummary, setEbidDetails } = props;
  const { auth } = useAuthContext();
  const { currentEbidResponse } = ebids || {};
  const { internalLoader } = shared || {};
  const { results } = bidssummary || {};

  const {
    supplierName = '',
    city = '',
    state = '',
    address1 = '',
    address2 = '',
    postalCode = '',
    phone = '',
    email = '',
    responseDateTime = '',
    totalPrice = '',
    dueDate = '',
    altTotalPrice = '',
    notes = '',
    responseStatus = '',
    responseId = '',
    declaredAttributes = '',
    bidId = '',
  } = currentEbidResponse || {};

  const checkduedate =
    deprecatedCheckTimezoneCountDown(dueDate, results ? results.tzfn : '') === 'closed'
      ? true
      : false;

  return (
    <>
      <h4>Supplier Details</h4>
      {currentEbidResponse && currentEbidResponse.supplierName ? (
        <>
          <header className='blockControls clearfix mb-3'>
            <ViewResponseHistory {...props} responseId={responseId} />
            {auth?.memberType === MemberType.AgencyBuyer ? (
              <>
                <Buttons
                  onClick={() => {
                    const wizard = [
                      { name: 'Contact Information', status: WizardStatus.Current, id: 1 },
                      { name: 'Documents Upload', status: WizardStatus.Unavailable, id: 2 },
                      { name: 'Review Bid', status: WizardStatus.Unavailable, id: 3 },
                    ] as WizardPage[];
                    setEbidDetails({ wizard });
                    history.push(`/buyers/bids/${bidId}/response/${responseId}`);
                  }}
                  text='Edit eBid Response'
                  title='Edit eBid Response'
                  classNames='bttn-secondary small float-right mr-2'
                />
              </>
            ) : null}
          </header>
          <div className='innerColIndent'>
            <Table borderless className='tableData'>
              <tbody>
                <tr>
                  <th style={tdWidth}>Supplier Name</th>
                  <td>{supplierName}</td>
                </tr>
                <tr>
                  <th>Address</th>
                  {address1 && city && state && postalCode ? (
                    <td>
                      {address1} {address2}, {city}, {state} {postalCode}
                    </td>
                  ) : null}
                </tr>
                {phone ? (
                  <tr>
                    <th>Phone Number</th>
                    <td>{formattedPhoneNumber(phone)}</td>
                  </tr>
                ) : null}
                {MemberType.AgencyBuyer === auth?.memberType && declaredAttributes ? (
                  <tr>
                    <th>Self Declarations</th>
                    <td>{declaredAttributes}</td>
                  </tr>
                ) : null}
                {email ? (
                  <tr>
                    <th>Email</th>
                    <td>{email}</td>
                  </tr>
                ) : null}
                {results && results.tzfn ? (
                  <tr>
                    <th>Bid Response Date</th>
                    <td className='text-red200'>
                    {displayDateTime(responseDateTime, results.tzfn)}
                    </td>
                  </tr>
                ) : null}
                {(checkduedate || results?.tse === 3) && (
                  <tr>
                    <th>Bid Amount</th>
                    <td>{totalPrice && `$${formatPrice(totalPrice, 2)}`}</td>
                  </tr>
                )}
                {(checkduedate || results?.tse === 3) && altTotalPrice && (
                  <tr>
                    <th>Alternate Bid Amount</th>
                    <td>{totalPrice && `$${formatPrice(altTotalPrice, 2)}`}</td>
                  </tr>
                )}
                {responseStatus && (
                  <tr>
                    <th>Response Status</th>
                    <td>
                      {responseStatus.toLowerCase() === 'incomplete' ? (
                        <span className='text-red200 font-weight-bold'>{responseStatus}</span>
                      ) : (
                        responseStatus
                      )}
                    </td>
                  </tr>
                )}
                {notes && (
                  <tr>
                    <th>Notes</th>
                    <td>{notes}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        <NoResult message={internalLoader ? loadingMsg : 'No Supplier Details Available'} />
      )}
    </>
  );
}

export default memo(EbidSupplierDetails);
