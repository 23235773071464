import { Assert } from '@demandstar/components/utils';

import {
  AwardeeContact,
  ContractDetailsAPI,
  ContractDetailsDraft,
  ContractSearchResultAPI,
  MemberAward,
} from 'src/features/contract-management/contract-management.d';
import {
  axiosBarePostAuthenticated,
  axiosBareRequestAuthenticated,
  axiosPostAuthenticated,
} from '../../store/services/api';

import { constants } from '../../utils/settings';
import { paths } from './ContractManagement.paths';
import { Paths } from '../../utils/constants';

/**
 * Get Contract Request
 * @param {string} contractId
 */
export async function getContract(contractId: string) {
  const getContractResponse = await axiosBareRequestAuthenticated({
    baseURL: constants.api.reminderUrl,
    url: paths.api.contracts.get(contractId),
  }) as {data:ContractDetailsAPI};
  return getContractResponse.data;
}

export async function getAllContracts() {
  const getAllContractsResponse = await axiosBarePostAuthenticated({
    baseURL: constants.api.reminderUrl,
    data:{},
    url: paths.api.contracts.search,
  }) as {data:ContractSearchResultAPI[]};
  return getAllContractsResponse.data;
}

/**
 * Update Contract Request
 * @param {ContractDetails} contract
 */
export async function updateContract(contract: ContractDetailsDraft) {
  const upgradeContractResponse =await axiosBarePostAuthenticated({
    baseURL: constants.api.reminderUrl,
    data: {
      ...contract,
    },
    url: contract.id ? paths.api.contracts.update(contract.id) : paths.api.contracts.create,
  }) as {data:ContractDetailsAPI};
  return  upgradeContractResponse.data ;
}

/**
 * Publish Contract
 * @param {ContractDetails} contract
 */
export async function publishContract(contractId: string) {
  const publishContractResponse = await axiosBarePostAuthenticated({
    baseURL: constants.api.reminderUrl,
    url: paths.api.contracts.publish(contractId),
  })as {data:ContractDetailsAPI};
  return publishContractResponse.data;
}

/**
 * Add AwardeeContact
 * @param {AwardeeContact} contact awardee contact
 */
export async function addAwardeeContact(contractId: string, contact: AwardeeContact) {
  Assert(!contact.id, 'Contact must not have an id if we are adding');
  const addAwardeeContactResponse = await axiosBarePostAuthenticated({
    baseURL: constants.api.reminderUrl,
    data:{id:contractId,lstAwardeeContactCreateRequest: [contact]},
    url: paths.api.awardees.add,
  }) as{data:ContractDetailsAPI};
  return addAwardeeContactResponse.data ;
}

/**
 * Edit AwardeeContact
 * @param {AwardeeContact} contact awardee contact
 */
export async function updateAwardeeContact(contractId: string, contact: AwardeeContact) {
  Assert(contact.id, 'Contact must have an id if we are updating');
  const updateAwardeecontactResponse = await  axiosBarePostAuthenticated({
    baseURL: constants.api.reminderUrl,
    data: contact,
    url: paths.api.awardees.update(contractId, contact.id),
  }) as {data:ContractDetailsAPI};
  return updateAwardeecontactResponse.data ;
}

/**
 * Delete AwardeeContact */
export async function deleteAwardeeContactAPI(contractId: string, awardeeId: string) {
  const delAwardeecontactResponse = await axiosBareRequestAuthenticated({
    baseURL: constants.api.reminderUrl,
    url: paths.api.awardees.delete(contractId, awardeeId),
    method: 'DELETE',
  }) as{data:ContractDetailsAPI};
  return delAwardeecontactResponse.data ;
}

/**
 * Get Member Awards
 */
export function getMemberAwards(): Promise<MemberAward[]> {
  return axiosPostAuthenticated({
    baseURL: constants.api.url,
    url: Paths.Award.memberAwards,
  });
}
