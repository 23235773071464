import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Fragment } from 'react';
import styled from 'styled-components';

import {
  DSAccordionCard,
  DSAccordionCardProps,
} from '@demandstar/components/accordion/DSAccordionCard';
import {
  Flex,
  FlexContainer,
  FlexContainerColumn,
  P,
  ParagraphFontSize,
} from '@demandstar/components/styles';
import { DSButton } from '@demandstar/components/button';
import { DSEmail } from '@demandstar/components/email';
import { DSLink } from '@demandstar/components/link';
import { LabelValuePair } from '@demandstar/components/styles';
import { Status } from '@demandstar/components/constants';

import * as texts from './ReminderCard.texts';
import { displayDate, getRelativeDate } from 'src/utils/helpers';
import { ConfirmModal } from 'src/components/common/modals';
import { ContractReminder } from './hook/reminders.d';
import { EditReminder } from './EditReminder';
import { paths } from '../../ContractManagement.paths';
import { useContractDetails } from '../../useContractDetails';
import { useReminders } from './hook/useReminders';

type ReminderCardProps = Pick<
  DSAccordionCardProps,
  'compact' | 'isOpen' | 'dataTestId' | 'onClick' | 'useDefaultBehavior'
> & {
  reminder: ContractReminder;
};

const ReminderBody = styled(P)<Pick<ReminderCardProps, 'compact'>>`
  ${({ compact }) =>
    compact
      ? `
    -webkit-box-orient: vertical; 
    -webkit-line-clamp: 3;
    display: -webkit-box;
    overflow: hidden;
  `
      : ''}
`;

const SendAgainText = styled(P)`
  font-size: ${ParagraphFontSize.Small};
  text-align: center;
  white-space: nowrap;
`;

export const ReminderCard = ({
  compact,
  dataTestId,
  isOpen,
  onClick,
  reminder,
  useDefaultBehavior,
}: ReminderCardProps) => {
  const { body, contractId, dueDate, emails, id, remindDate, sendCopy, subject } = reminder;
  const { contractDetails } = useContractDetails(contractId);
  const { dismissReminder, sendReminder } = useReminders();
  const [editing, setEditing] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const toggleEditing = () => {
    setEditing(e => !e);
  };

  const toggleDelete = () => {
    setDeleteModalOpen(d => !d);
  };

  const { status, message: statusMessage } = useMemo(() => {
    if (dueDate.isBefore(dayjs().startOf('day'))) {
      return { status: Status.Error, message: 'Overdue' };
    }

    if (dueDate.isSame(dayjs(), 'day')) {
      return { status: Status.Warning, message: 'Due Today' };
    }

    if (dueDate.isBefore(dayjs().add(7, 'day').endOf('day'))) {
      return {
        status: Status.Warning,
        message: `Due ${getRelativeDate(dueDate)}`,
      };
    }

    return {
      status: Status.Success,
      message: `Due ${displayDate(dueDate)}`,
    };
  }, [dueDate]);

  const editable = useMemo(() => remindDate.isAfter(dayjs()), [remindDate]);
  const sendAllowed = useMemo(
    () => editable || dayjs().isAfter(remindDate.add(24, 'hour')),
    [editable, remindDate],
  );
  const sendAgainIn = useMemo(
    () => texts.sendAgainIn(getRelativeDate(remindDate.add(24, 'hour'))),
    [remindDate],
  );

  async function remove() {
    await dismissReminder(reminder);
    toggleDelete();
  }

  async function dismiss() {
    await dismissReminder(reminder);
  }

  async function resend() {
    await sendReminder(id);
  }

  return (
    <DSAccordionCard
      compact={compact}
      dataTestId={dataTestId || `reminder-card-${id}`}
      header={subject}
      isOpen={isOpen}
      onClick={onClick}
      status={status}
      statusMessage={statusMessage}
      useDefaultBehavior={useDefaultBehavior}
    >
      {editing ? (
        <EditReminder
          dataTestId={`${dataTestId}-edit`}
          toggleEditing={toggleEditing}
          reminder={reminder}
        />
      ) : (
        <>
          {body && (
            <FlexContainer>
              <Flex grow={1}>
                <ReminderBody title={body} compact={compact}>
                  {body}
                </ReminderBody>
              </Flex>
              {editable && !compact && (
                <Flex grow={0}>
                  <DSLink dataTestId={`${dataTestId}-edit-toggle`} onClick={toggleEditing}>
                    {texts.edit}
                  </DSLink>
                </Flex>
              )}
            </FlexContainer>
          )}

          {!compact && !!emails.length && (
            <LabelValuePair vertical>
              <label>{texts.sentTo}</label>
              <P data-testid='reminder.emails.list'>
                {emails?.split(',').map((e, idx) => (
                  <Fragment key={e}>
                    <DSEmail>{e}</DSEmail>
                    {idx !== emails.split(',').length - 1 ? ', ' : ''}
                  </Fragment>
                ))}
                {sendCopy && `, ${texts.yourself}`}
              </P>
            </LabelValuePair>
          )}

          <FlexContainer
            alignItems={compact ? 'flex-start' : 'flex-end'}
            justifyContent={'space-between'}
            direction={compact ? 'column' : 'row'}
          >
            <Flex grow={1}>
              <FlexContainerColumn>
                {!compact && (
                  <LabelValuePair vertical>
                    <label>{texts.remindOn}</label>
                    {displayDate(remindDate)}
                  </LabelValuePair>
                )}

                {contractDetails && (
                  <LabelValuePair vertical>
                    <label>{texts.contract}</label>
                    <DSLink to={paths.navigation.contractDetails(contractId)}>
                      {contractDetails?.name || ''}
                    </DSLink>
                  </LabelValuePair>
                )}
              </FlexContainerColumn>
            </Flex>

            {!compact ? (
              <Flex grow={0}>
                <FlexContainerColumn justifyContent='flex-end' alignItems='stretch'>
                  <DSButton
                    onClick={resend}
                    type='tertiary'
                    inactive={!sendAllowed}
                    title={sendAllowed ? undefined : sendAgainIn}
                    contrast
                  >
                    {editable ? texts.sendNow : texts.sendAgain}
                  </DSButton>
                  <SendAgainText>{!sendAllowed && sendAgainIn}</SendAgainText>
                  {editable ? (
                    <DSButton onClick={toggleDelete} type='danger'>
                      {texts.deleteReminder}
                    </DSButton>
                  ) : (
                    <DSButton onClick={dismiss} type='tertiary'>
                      {texts.dismiss}
                    </DSButton>
                  )}
                </FlexContainerColumn>
              </Flex>
            ) : (
              <FlexContainerColumn justifyContent='flex-end' alignItems='flex-end'>
                <DSLink to={paths.navigation.reminders(contractId, { reminderId: id })}>
                  {texts.seeDetails}
                </DSLink>
              </FlexContainerColumn>
            )}
            <ConfirmModal
              cancelAction={toggleDelete}
              closeModal={toggleDelete}
              title={texts.confirmDeleteTitle}
              onConfirm={remove}
              isOpen={deleteModalOpen}
              danger
            >
              {texts.confirmDelete}
            </ConfirmModal>
          </FlexContainer>
        </>
      )}
    </DSAccordionCard>
  );
};
