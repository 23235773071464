import { useCallback, useMemo } from 'react';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';

import { bidAwardeesQuerySelector, bidAwardeesRequestIdState } from './bid-awardees.recoil';
import { useRouter, useUpdateAwardeeAndNonAwardeeEmailInfo } from 'src/shared/hooks';
import { addAwardees } from 'src/store/services';
import { AwardeeDraft } from './bid-awardees';
import { setSharedDetails } from '../../actions';
import { useDispatch } from 'react-redux';

/** hook for accessing bidAwardees from a single bidId. */
export function useBidAwardees(id?: number) {
  const { routerParams } = useRouter();
  const dispatch = useDispatch();
  const updateAwardeeAndNonAwardeeEmailInfo = useUpdateAwardeeAndNonAwardeeEmailInfo();
  const bidId = id || (routerParams.bidId && Number(routerParams.bidId)) || undefined;

  const setBidAwardeesRequestId = useSetRecoilState(bidAwardeesRequestIdState(bidId));
  const bidAwardeesLoadable = useRecoilValueLoadable(bidAwardeesQuerySelector(bidId));
  const bidAwardees = useMemo(() => {
    return bidAwardeesLoadable.valueMaybe() || [];
  }, [bidAwardeesLoadable]);

  /**
   * manually update bidAwardees of the bidId passed to useAwardees
   */
  const refreshBidAwardees = useCallback(() => {
    setBidAwardeesRequestId(requestId => requestId + 1);
  }, [setBidAwardeesRequestId]);

  /**  */
  const updateAwardees = useCallback(
    async (bidAwardees: AwardeeDraft[]) => {
      dispatch(setSharedDetails({ showLoader: true }));
      if (bidId) {
        await addAwardees({
          bidId,
          bidAwardees,
        });
        refreshBidAwardees();
      }
      /** Because this will only be called within the Award Bid Wizard,
       * this forces an update for email message info. (Specifically, the recipients will change)
       */
      updateAwardeeAndNonAwardeeEmailInfo();
      dispatch(setSharedDetails({ showLoader: false }));
      return;
    },
    [bidId, refreshBidAwardees, updateAwardeeAndNonAwardeeEmailInfo,dispatch],
  );

  return {
    refreshBidAwardees,
    bidAwardees,
    bidAwardeesLoadable,
    updateAwardees,
  };
}
